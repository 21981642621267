import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { UserGroup, UserGroupsRepository } from './usergroup.repository';
import { SortState } from 'app/modules/shared/pipes/sort.pipe';
import { DatalistOption } from './abstract/base.repository';

const API = '/api/usergroup';

@Injectable({ providedIn: 'root' })
export class UserGroupsService extends BaseService<UserGroup> {
  constructor(http: HttpClient, public repo: UserGroupsRepository) {
    super(API, http, repo);
  }

  sendEmail(html: string, userGroupId: string) {
    return this.http.post(`${API}/send`, {
      email: html,
      usergroupId: userGroupId,
    });
  }

  getActiveUserGroupIds() {
    return this.http.get<string[]>(API + '/getActiveUserGroupIds');
  }

  loadAll() {
    return this.http
      .get<UserGroup[]>(`${API}/all`)
      .pipe(tap((userGroups) => this.repo.setUserGroups(userGroups)));
  }

  loadAllDeleted() {
    return this.http.get<UserGroup[]>(API + '/alldeleted');
  }

  update(id: string, model: Partial<UserGroup>) {
    return this.http.put<UserGroup>(`${API}/edit/${id}`, model);
  }

  loadForDatalist() {
    const activeTenantId = localStorage.getItem('activeTenantId');
    const query = [`activeTenantId=${activeTenantId}`];
    return this.http.get<DatalistOption[]>(
      `${API}/ugForDatalist?${query.join('&')}`
    );
  }

  loadAllWithId(id: string) {
    const query = [`id=${id}`];
    return this.http.get<UserGroup[]>(`${API}/loadall?${query.join('&')}`);
  }

  loadOneForAdmin(id: string): Observable<UserGroup> {
    const query = [`id=${id}`];
    return this.http.get<UserGroup>(`${API}/loadone?${query.join('&')}`);
  }

  loadPageForUser(
    search: string = '',
    my: boolean = false,
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: UserGroup[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `my = ${my}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: UserGroup[] }>(
        `${API}/foruser?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sortForUser(sortBy: SortState, search?: string, my: boolean = false) {
    this.repo.setSort(sortBy);
    return this.reloadPageForUser(search, my, DEFAULT_ENTITIES_PER_PAGE);
  }

  subscribeOnGroup(id: string) {
    return this.http.get<UserGroup>(`${API}/subscribe/${id}`);
  }

  reloadPageForUser(
    search: string = '',
    my: boolean = false,
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: UserGroup[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageForUser(search, my, data.currentPage, data.perPage);
    }
    return this.loadPageForUser(search, my, 1, defaultTake);
  }
}

<div class="box mt-3 m-0 p-0 row" style="width: 100%">
  <div class="box-name-header" style="width: 100%">
    <div class="mt-1 ms-1 row pt-1 m-0 p-0">
      <app-sort-table
        [items]="filteredTenants"
        [sortProperties]="sortProps"
        (sortedItems)="(filteredTenants)"
      />
    </div>
  </div>

  <br />
  <div *ngFor="let tenant of filteredTenants" class="row p-0 m-0">
    <div class="brd row m-0 p-0">
      <div class="col-7 col-lg-4 pt-3 pb-3 d-flex align-items-center psm0">
        <app-entity-avatar
          [img]=""
          [alt]="tenant.name"
          icon="bi-building"
        ></app-entity-avatar>
        <p>
          <span
            class="txt-name text-break ms-1"
            [routerLink]="['/tenants', tenant.id]"
            >{{ tenant.name }}</span
          >
          <br />
          <small>
            <span class="txt-sub text-break ms-1">
              {tenant.countOfUsers, plural, =1 {1 user} other
              {{{tenant.countOfUsers}} users}}</span
            >
          </small>
        </p>
      </div>
      <div *ngIf="innerWidth > 991" class="col-5 d-flex align-items-center">
        <span class="role ms-1" i18n="Label created date">
          {{ tenant.createdAt | date : "dd-MM-yyyy hh:mm" }}</span
        >
      </div>
      <div
        *ngIf="auth.isAnyAdmin$ | async"
        class="col-5 col-lg-3 d-flex align-items-center p-0 justify-content-end"
      >
        <div>
          <a
            class="btn btn-md btn-link w-100"
            [routerLink]="['/tenants/html', tenant.id]"
          >
            <img src="assets/icons/homeHtml.svg" />
          </a>
        </div>
        <div>
          <a
            class="btn btn-md btn-link w-100"
            [routerLink]="['/tenants/info', tenant.id]"
          >
            <img title="{{ 'info' | transloco }}" src="assets/icons/info.svg" />
          </a>
        </div>
        <div>
          <a
            class="btn btn-md btn-link w-100"
            [routerLink]="['/tenants', tenant.id]"
          >
            <img src="assets/img/edit.svg" />
          </a>
        </div>
        <div>
          <a
            class="btn btn-md btn-link w-100"
            [routerLink]="['/deleting', tenant.id]"
          >
            <img src="assets/icons/permanently-delete.svg" />
          </a>
        </div>
        <div>
          <a
            class="btn btn-md btn-link w-100"
            [routerLink]="['/tenants/msGraphOptions', tenant.id]"
          >
            <img src="assets/icons/mail-01.svg" />
          </a>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-md btn-link w-100 text-danger"
            data-bs-target="#cloneusermodal"
            (click)="cloneConfirmation = tenant"
            title="{{ 'clone' | transloco }}"
          >
            <img src="assets/img/clone.svg" />
          </button>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-md btn-link w-100 text-danger"
            data-bs-target="#deleteusermodal"
            (click)="deleteConfirmation = tenant"
            title="Delete"
          >
            <img src="assets/img/delete.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-delete
  id="deleteusermodal"
  *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="handleDeleteClick(deleteConfirmation); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
<app-clone-pop-up
  id="cloneusermodal"
  *ngIf="cloneConfirmation"
  [cloneCandidateId]="cloneConfirmation.id"
  [cloneCandidateName]="cloneConfirmation.name"
  (confirm)="clone.emit($event); cloneConfirmation = null"
  (cancel)="cloneConfirmation = null"
></app-clone-pop-up>

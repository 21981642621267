import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { MWEventService } from 'app/state/mwevent.service';
import { IConfirm } from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';

@Component({
  selector: 'app-clone-pop-up',
  templateUrl: './clone-pop-up.component.html',
  styleUrls: ['./clone-pop-up.component.scss'],
})
export class ClonePopUpComponent {
  @Output() confirm = new EventEmitter<IConfirm>();
  @Output() cancel = new EventEmitter<void>();
  @Input() cloneCandidateId: string | null = null;
  @Input() cloneCandidateName: string = '';
  Form: UntypedFormBuilder | any;

  @Input() currentTenant: string | null = null;
  @Input() isEmail: boolean = false;
  tenantsOptions: DatalistOption[] | null = null;

  constructor(
    private tenantService: TenantsService,
    public service: MWEventService,
    private formBuilder: UntypedFormBuilder
  ) {}
  ngOnInit(): void {
    this.tenantService.loadForUser().subscribe((value) => {
      if (!value) {
        this.tenantsOptions = null;
      } else {
        this.tenantsOptions = value.map((x) => ({
          value: x.id,
          label: x.name,
        }));
      }
    });

    this.Form = this.formBuilder.group({
      title: [this.cloneCandidateName, Validators.required],
      id: this.cloneCandidateId,
      tenantId: this.currentTenant
        ? [this.currentTenant, Validators.required]
        : null,
      withUsergroups: false,
    });
  }
  submit() {
    if (this.Form && !this.Form.valid) {
      this.Form.markAllAsTouched();
      return;
    }
    const title = this.Form.value?.title;
    const id = this.Form.value?.id;
    const tenantId = this.Form.value?.tenantId;
    const withUsergroups = this.Form.value?.withUsergroups;
    this.confirm.emit({
      title,
      id,
      tenantId: tenantId ? tenantId : undefined,
      withUsergroups,
    });
  }
  updateValue(control: string, value: any) {
    const controlObject = this.Form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}

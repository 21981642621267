<div
  class="modal fade"
  bsModal
  id="buyModal"
  tabindex="-1"
  [config]="{ show: true }"
  #buyModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0 br-16">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="buyModalLabel"
          i18n="Title for confirmation dialog"
        >
          Confirm your action
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div *ngIf="Form" class="col-10 col-sm-11">
        <form
          [formGroup]="Form"
          (ngSubmit)="submit()"
          enctype="multipart/form-data"
        >
          <div class="modal-body">
            <span>
              <p class="mb-1" i18n="Purchase type">
                Please select purchase type:
              </p>
              <div
                class="form-group form-floating mb-3"
                [appValidateState]="Form.get('purchaseType')"
              >
                <app-datalist-select
                  class=""
                  [activeValue]="Form?.value.purchaseType"
                  [options]="purchaseTypeOptions"
                  (activeValueChange)="
                    updateValue('purchaseType', $event); currentTenant = $event
                  "
                  [multiple]="false"
                ></app-datalist-select>
              </div>
            </span>
            <div class="form-floating mb-3">
              <p class="mb-1" i18n="Confirm buy message start">Name</p>
              <p
                class="mb-1"
                class="form-group form-floating mb-2"
                [appValidateState]="Form.get('title')"
              >
                <input class="inpt" formControlName="title" />
              </p>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-orange me-2" type="submit" i18n="Buy button">
              Buy
            </button>
            <button class="btn btn-d" (click)="cancel.emit()" i18n="@@cancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

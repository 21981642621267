import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { switchMap } from 'rxjs';
import { BaseService } from './abstract/base.service';
import { IMarketEntity, MarketplaceRepository } from './marketplace.repository';
import { IConfirm } from './tenants.repository';

const API = '/api/marketplace';
const APILogs = '/api/marketeventlog';

@Injectable({ providedIn: 'root' })
export class MarketplaceService extends BaseService<IMarketEntity> {
  constructor(http: HttpClient, repo: MarketplaceRepository) {
    super(API, http, repo);
  }

  loadOneMarketEvent(id: string) {
    return this.http.get<IMarketEntity>(`${API}/loadOne/${id}`);
  }

  add(entity: Partial<IMarketEntity>) {
    const formData = new FormData();
    formData.append('file', entity.file!);
    return this.http
      .post<IMarketEntity>(API, entity)
      .pipe(
        switchMap((entity) =>
          this.http.post<IMarketEntity>(
            `${API}/${entity.id}/file/${true}`,
            formData
          )
        )
      );
  }

  updateWithTenantOption(
    id: string,
    withTenant: boolean,
    entity: Partial<IMarketEntity>
  ) {
    const formData = new FormData();
    formData.append('file', entity.file!);

    return this.http
      .patch<IMarketEntity>(`${API}/${id}/${withTenant}`, entity)
      .pipe(
        switchMap((entity) =>
          this.http.post<IMarketEntity>(
            `${API}/${entity.id}/file/${withTenant}`,
            formData
          )
        )
      );
  }

  loadAllWithoutTenant() {
    return this.http.get<PaginationData & { data: IMarketEntity[] }>(
      API + '/loadAll/WithoutTenant'
    );
  }

  buy(con: IConfirm) {
    return this.http.patch<IMarketEntity>(API + '/buy/', con);
  }
  //
  clone(con: IConfirm) {
    return this.http.patch<IMarketEntity>(API + '/WithoutTenant/clone/', con);
  }
}

<div *ngIf="toolBox">
  <div class="row justify-content-center">
    <div
      class="row col-12 col-xl-10 col-lg-11 pb-3 pt-0 pt-sm-3 ps-0 pe-0 d-flex justify-content-center"
    >
      <div>
        <div class="date mb-2">
          {{ toolBox.createdAt | date : "d MMM y" }}
        </div>

        <div class="mainTitle text-breaktext-break pb-4">
          {{ toolBox.title }}
        </div>
        <div
          class="mb-3 mb-md-5 d-flex flex-column justify-content-center align-items-center"
          *ngIf="previewDocuments && previewDocuments.length > 0"
        >
          <div class="mb-2">
            <img
              *ngIf="
                previewDocuments[0].image && previewDocuments[0].type == 'image'
              "
              class="img"
              style="max-width: 100%; max-height: 50vh"
              [src]="previewDocuments[0].image"
              title="{{ previewDocuments[0].altText }}"
            />
          </div>

          <iframe
            *ngIf="
              previewDocuments[0].videoUrl &&
              previewDocuments[0].type == 'videoUrl'
            "
            class="urlvideo"
            style="height: 50vh; width: 100%"
            [src]="previewDocuments[0].videoUrl"
            allowscriptaccess="always"
            frameborder="0"
            playsinline
          >
          </iframe>

          <video
            class="w-100"
            style="height: 50vh"
            *ngIf="
              previewDocuments.length &&
              (previewDocuments[0].video || previewDocuments[0].videoCompressed)
            "
            muted
            preload="true"
            controls
          >
            <source [src]="previewDocuments[0].video" type="video/mp4" />
            <source [src]="previewDocuments[0].video" type="video/ogg" />
            {{ "yourBrowserDoesNotSupportTheVideoTag" | transloco }}
          </video>
        </div>

        <div>
          <p class="text-break content" [innerHTML]="toolBox.content"></p>
        </div>
      </div>
      <div
        *ngIf="attachedLinks && attachedLinks.length > 0"
        class="mt-2"
        id="attachedLinksSection"
      >
        <div>
          <div class="mb-2">
            <div class="comments">{{ "attachedLinks" | transloco }}</div>
          </div>
          <div
            *ngFor="let card of attachedLinks"
            class="d-flex flex-row align-items-start"
          >
            <div
              *ngIf="card.url"
              class="docCard oneLineLink"
              (click)="openLinkInNewTab(card.url)"
            >
              {{ card.text }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="attachedDocuments.length" class="mt-2" id="documentsSection">
        <div>
          <div class="mb-2">
            <div class="comments">{{ "attachedFiles" | transloco }}</div>
          </div>
          <div
            *ngFor="let card of attachedDocuments"
            class="d-flex flex-row align-items-start"
          >
            <div
              *ngIf="card.documentPath"
              class="docCard oneLineLink"
              (click)="
                blobService.openFileFromBlob(
                  card.documentPath,
                  card.isFileOnRightBlob ?? false
                )
              "
            >
              {{
                card.documentPath.length > 36
                  ? card.documentPath.slice(36, card.documentPath.length)
                  : card.documentPath
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

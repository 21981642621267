<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="row col-12 col-lg-11 col-xl-10 pb-3 pt-sm-3 ps-0 pe-0">
    <div class="col-lg-6 col-12 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label marketplace">
        {{ "marketplace" | transloco }}
      </h2>
    </div>
    <div class="col-sm-6 col-8 mb-sm-5 d-flex justify-content-sm-end">
      <div class="butsect d-flex justify-content-center align-items-center">
        <a href="/marketplace/public" class="btn button me-1">{{
          "public" | transloco
        }}</a>
        <a class="btn button butActive">{{ "private" | transloco }}</a>
      </div>
    </div>
    <div
      *ngIf="innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mb-sm-3 p-0 d-flex justify-content-end"
    ></div>
    <div
      *ngIf="innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <a
        routerLink="/admin/marketplace/new"
        class="btn btn-lg orange-dark text-white"
        style="justify-content: space-between"
      >
        <img src="assets/img/plus_add.svg" />

        <span
          style="font-size: 14px"
          class="text-btn-add"
          i18n="@@addDocumenttype"
          >{{ "addEvent" | transloco }}</span
        >
      </a>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="service.sort($event, searchFilter).subscribe()"
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-5 col-5 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">{{ "name" | transloco }}</span>
          </div>

          <div class="col-sm-5 col-5 d-flex psm0">
            <span class="txt mt-1">{{ "tenant" | transloco }}</span>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let item of repo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-5 col-5 pt-3 pb-3 d-flex align-items-center psm0">
          <input
            class="check form-check-input me-2"
            type="checkbox"
            style="margin-left: 0.1rem"
          />
          <div class="txt-name">
            <div [routerLink]="['/admin/marketplace/edit', item.id]">
              {{ item.title | slice : 0 : 50 }}
            </div>
          </div>
        </div>
        <div class="col-sm-5 col-5 pt-3 pb-3 d-flex align-items-center psm0">
          <div class="txt-name">
            <div [routerLink]="['/admin/marketplace/edit', item.id]">
              {{ item.tenantName | slice : 0 : 50 }}
            </div>
          </div>
        </div>

        <div
          *ngIf="innerWidth > 575"
          class="col-2 d-flex align-items-center p-0 justify-content-end"
        >
          <div>
            <a
              class="btn btn-md btn-link w-100"
              (click)="cloneConfirmation = item"
              title="{{ 'clone' | transloco }}"
              i18n-title="Clone button"
            >
              <img src="assets/img/clone.svg" />
            </a>
          </div>
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/admin/marketplace/edit', item.id]"
              title="{{ 'edit' | transloco }}"
              i18n-title="Edit button"
            >
              <img src="assets/img/edit.svg" />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = item"
              title="{{ 'delete' | transloco }}"
              i18n-title="Delete button"
            >
              <img src="assets/img/delete.svg" />
            </button>
          </div>
        </div>
      </div>
      <br />

      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service.loadFilterPage(searchFilter, $event).subscribe()
          "
        ></app-custom-pagination>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
    <app-clone-pop-up
      id="cloneusermodal"
      *ngIf="cloneConfirmation"
      [cloneCandidateId]="cloneConfirmation.id"
      [cloneCandidateName]="cloneConfirmation.title"
      [currentTenant]="tenantId"
      (confirm)="handleCloneClick($event); cloneConfirmation = null"
      (cancel)="cloneConfirmation = null"
    ></app-clone-pop-up>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortProperty } from 'app/interfaces/sort-property';

@Component({
  selector: 'app-sort-table',
  templateUrl: './sort-table.component.html',
  styleUrls: ['./sort-table.component.scss'],
})
export class SortTableComponent {
  @Input() items?: any[] | null;
  @Input() sortProperties?: SortProperty[] | null;
  @Output() sortedItems = new EventEmitter<any[]>();

  constructor() {}

  sortByField(property: SortProperty) {
    this.sortProperties = this.sortProperties?.map((mapProp) => {
      if (mapProp.target !== property.target) {
        return {
          ...mapProp,
          isAscending: null,
        };
      } else return mapProp;
    });
    if (property && this.items?.length) {
      property.isAscending = !property.isAscending;
      this.sortedItems.emit(
        this.items?.sort((a, b) => {
          const fieldA = a[property.target!];
          const fieldB = b[property.target!];

          if (fieldA === undefined || fieldB === undefined) return 0;

          let comparison = 0;
          if (typeof fieldA === 'string') {
            comparison = fieldA.localeCompare(fieldB);
          } else if (typeof fieldA === 'number' || fieldA instanceof Date) {
            comparison =
              (fieldA instanceof Date ? fieldA.getTime() : fieldA) -
              (fieldB instanceof Date ? fieldB.getTime() : fieldB);
          } else if (typeof fieldA === 'boolean') {
            comparison = fieldA === fieldB ? 0 : fieldA ? 1 : -1;
          }

          return property.isAscending ? comparison : -comparison;
        })
      );
    }
  }
}

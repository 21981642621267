import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  Observable,
  combineLatest,
  map,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { MarketCategoriesRepository } from 'app/state/marketcategories.repository';
import { MarketCategoriesService } from 'app/state/marketcategories.service';
import { MarketplaceService } from 'app/state/marketpalce.service';
import { MarketplaceAllService } from 'app/state/marketpalceAll.service';
import {
  IMarketEntity,
  MarketCategory,
  MarketplaceRepository,
} from 'app/state/marketplace.repository';
import { IConfirm } from 'app/state/tenants.repository';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-marketplace-info',
  templateUrl: './marketplace-info.component.html',
  styleUrls: ['./marketplace-info.component.scss'],
})
export class MarketplaceInfoComponent {
  market$!: Observable<IMarketEntity>;
  image = 'assets/img/product1.jpg';
  DEFAULT_MARKET = 'assets/img/market.jpg';
  buyConfirmation: IMarketEntity | null = null;
  Datejs: dayjs.Dayjs = dayjs();
  tenantId: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private repo: MarketplaceRepository,
    private service: MarketplaceService,
    private categoryService: MarketCategoriesService,
    public categoryRepo: MarketCategoriesRepository
  ) {}

  ngOnInit(): void {
    this.tenantId = localStorage.getItem('activeTenantId');
    const id$ = this.route.paramMap.pipe(map((param) => param.get('id')!));
    id$
      .pipe(
        // request data
        switchMap((id) => (this.market$ = this.service.loadOneMarketEvent(id)))
      )
      .subscribe();
    // this.market$ = id$.pipe(
    //   switchMap(id => combineLatest([this.repo.one(id), this.repo.statusOne(id)])),
    //   skipWhile(([_, status]) => status.value === 'pending' || status.value === 'idle'),
    //   map(([post, _]) => post),
    //   tap(post => {
    //     if (!post) {
    //       this.router.navigate(['/not-found'], { skipLocationChange: true });
    //     }
    //   }),
    //   filterNil()
    // );
    this.categoryService.load().subscribe();
  }

  findName(categories: MarketCategory[] | null, id: string) {
    if (categories) {
      let category = categories.find((x) => x.id === id);
      if (category) {
        return category.name;
      }
    }
    return '';
  }

  buyMarket(market: IConfirm) {
    this.service.buy(market).subscribe(() => {
      this.router.navigate(['/mwevents/admin']);
    });
  }
}

<ng-template #email>{{ repo.email$ | async }}</ng-template>
<div class="d-flex flex-column bt" style="width: 100%">
  <div class="d-flex flex-row ms-1 me-1 pt-3">
    <div class="d-flex flex-row align-items-center">
      <div class="d-flex justify-content-center flex-column me-3">
        <a
          routerLink="/profile"
          class="position-relative d-block d-flex justify-content-center"
          (click)="handleClick($event)"
        >
          <div class="avatar avatar-40 rounded-circle mx-auto bg-secondary">
            <img
              [src]="(repo.image$ | async) || DEFAULT_IMAGE"
              [alt]="repo.displayName$ | async"
            />
          </div>
        </a>
      </div>
      <div class="d-flex flex-column align-items-start">
        <div class="name text-break">
          <ng-container *ngIf="repo.displayName$ | async as name; else email">{{
            name
          }}</ng-container>
        </div>
        <div class="email text-break">
          <ng-container *ngIf="repo.email$ | async as email">{{
            email
          }}</ng-container>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row col justify-content-end align-items-start">
      <svg
        *ngIf="!(repo.isImpersonating$ | async)"
        width="18"
        height="16"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="logout()"
      >
        <path
          d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        *ngIf="repo.isImpersonating$ | async"
        width="18"
        height="16"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        (click)="unimpersonate()"
      >
        <path
          d="M12.3333 13.1667L16.5 9M16.5 9L12.3333 4.83333M16.5 9H6.5M6.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V12.5C1.5 13.9001 1.5 14.6002 1.77248 15.135C2.01217 15.6054 2.39462 15.9878 2.86502 16.2275C3.3998 16.5 4.09987 16.5 5.5 16.5H6.5"
          stroke="#98A2B3"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</div>

<ng-container *ngIf="market$ | async as market">
  <h1 class="ms-4 mt-4">
    {{ "marketplaceCategory" | transloco }} [{{
      findName(categoryRepo.all$ | async, market.marketCategoryId)
    }}]
  </h1>
  <div class="row mt-4 ms-4">
    <div class="col-12 position-relative px-0 mb-4">
      <img
        *ngIf="market?.imagePath"
        class="manageble"
        src="{{ market?.imagePath }}"
        onerror="this.onerror=null; this.src='assets/img/market.jpg'"
      />
      <img
        *ngIf="!market?.imagePath"
        class="manageble"
        src="{{ DEFAULT_MARKET }}"
      />
    </div>
    <div class="col-12 mb-4 ps-0">
      <h5 class="mb-1">{{ market?.title }}</h5>
      <p class="text-muted mb-2 small">{{ market?.createdAt | date }}</p>
    </div>
  </div>
  <!-- Discriptions -->
  <div class="row mb-4 ms-sm-3">
    <div [innerHTML]="market?.description" class="col-12 text-muted"></div>
  </div>
  <button
    type="button"
    class="btn btn-sm btn-success ms-4"
    (click)="buyConfirmation = market"
  >
    <h3 class="mb-0">{{ "buy" | transloco }}</h3>
  </button>
  <app-buy-markt-pop-up
    id="buyusermodal"
    *ngIf="buyConfirmation"
    [buyCandidate]="buyConfirmation"
    [buyCandidateName]="buyConfirmation.title"
    [currentTenant]="tenantId"
    (confirm)="buyMarket($event); buyConfirmation = null"
    (cancel)="buyConfirmation = null"
  >
  </app-buy-markt-pop-up>
</ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, switchMap, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import {
  IMarketEventLog,
  MarketplaceLogsBuyRepository,
} from './marketpalceLogsBuy.repository';

const API = '/api/marketeventlog';
const APILogs = '/api/marketrventlog';

@Injectable({ providedIn: 'root' })
export class MarketplaceLogsBuyService extends BaseService<IMarketEventLog> {
  constructor(http: HttpClient, repo: MarketplaceLogsBuyRepository) {
    super(API, http, repo);
  }
  override reloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = [],
    actionType: string = ''
  ): Observable<(PaginationData & { data: IMarketEventLog[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(
        data.currentPage,
        data.perPage,
        search,
        includeAttributes,
        actionType
      );
    }
    return this.loadPage(1, defaultTake, search, includeAttributes, actionType);
  }
  override loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    includeAttributes: string[] = [],
    actionType: string = ''
  ): Observable<PaginationData & { data: IMarketEventLog[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `actionType=${actionType}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: IMarketEventLog[] }>(
        `${API}/WithoutTenant?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }
  override sort(
    sortBy: SortState,
    search?: string,
    includeAttributes: string[] = [],
    actionType: string = ''
  ) {
    this.repo.setSort(sortBy);
    return this.reloadPage(
      search,
      DEFAULT_ENTITIES_PER_PAGE,
      includeAttributes,
      actionType
    );
  }
  override searchReloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = [],
    actionType: string = ''
  ): Observable<(PaginationData & { data: IMarketEventLog[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(
        1,
        data.perPage,
        search,
        includeAttributes,
        actionType
      );
    }
    return this.loadPage(1, defaultTake, search, includeAttributes, actionType);
  }
}

import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { AttachedLink, BaseRepository } from './abstract/base.repository';
import {
  sortBy,
  SortOption,
  SortState,
} from 'app/modules/shared/pipes/sort.pipe';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';

export interface IMWEvent {
  id: string;
  title: string;
  description?: string;
  date: Date;
  filePath: string;
  purchaseType?: string;
  fileName?: string;
  subevents: IMWSubevents[];
  isViewer?: boolean;
  viewersIds?: string[];
  isResponsible?: boolean;
  respIds?: string[];
  isSprintClosed: boolean;
  isActive: boolean;
  videoType: 'video' | 'videoUrl';
  createdAt: Date;
  usersIds?: string[];
  userThatViewId?: string | null;
  userThatViewImg?: string | null;
  userIsSprintClosed?: boolean;
  currentPosition?: number;
  allUsersAdded?: UserInfo[];
  usersThatView?: UserInfo[];
  mwMustWinBattleId?: string;
  preview?: boolean;
  url?: string;
  userGroupIds?: string[];
}

export interface IMWSubevents {
  id: string;
  mwEventId: string;
  title: string;
  description?: string;
  text: string;
  url: string;
  subeventType: string;
  headline?: string;
  questions: ISubeventQuestion[];
  ideas: IIdea[];
  comments: IComments[];
  sortCommentsAndIdeas: SortComentsAndIdeas[];
  startDate?: Date;
  endDate?: Date;
  videoType: 'video' | 'videoUrl';
  filePath?: string;
  fileName?: string;
  imagePath?: string;
  imageName?: string;
  imagePosition?: string;
  imageBackGroundColor?: string;
  order: number;
  usersThatView?: UserInfo[];
  active?: boolean;
  filePathCompressed?: string;
  iframe?: string;
  documents: DocumentSubmit[];
  attachedLinks: AttachedLink[];
  hasCompressedImage320?: boolean;
  hasCompressedImage800?: boolean;
}

export interface ExportRequest {
  id: string;
  withTF: boolean;
}

export interface Images {
  file: FormData;
  fileTop: FormData;
  fileMiddle: FormData;
  fileLeft: FormData;
  fileRight: FormData;
  fileBottom: FormData;
}

export interface UserInfo {
  id: string;
  imagePath: string;
  hasCompression?: boolean;
  nameSurname: string;
  email: string;
  engagement?: number;
  progression?: number;
  commentsGenerated?: number;
  level2CommentsGenerated?: number;
  lastLogin?: Date;
  status?: string;
  unratedIdeas?: number;
}

export interface ISubeventQuestion {
  id: string;
  question: string;
  answer?: string;
  canAnswer?: boolean;
  nameSurname?: string;
  otherAnswers?: UserAnswer[];
}

export interface UserAnswer {
  userName: string;
  answer: string;
}

export interface EditCommentIdeaDto {
  text?: string;
  title?: string;
  avoid?: string;
  file?: string;
}

export interface ISubeventAnswer {
  id?: string;
  subeventQuestionId: string;
  userId?: string;
  answer: string;
}

export interface PrioritizedDismised {
  ideas: SortIdeas[];
  prioritizedIdeas: SortIdeas[];
  dismissedIdeas: SortIdeas[];
  allSorted: SortIdeas[];
}

export interface SortIdeas {
  idea: IIdea;
  status?: string;
  effortAvg: number;
  impactAvg: number;
  emojiAvg: number;

  rate: number;
  color: string;
}

export interface SortComentsAndIdeas {
  createdAt: Date;
  type: string;
  idea: IIdea | null;
  comment: IComments | null;
  ideaRating?: IdeaRating | null;
  isShowing: boolean | false;
  effortAvg?: number;
  impactAvg?: number;
  isAnon?: boolean;
  avgRates?: AvgRates;
}
export interface AvgRates {
  rate?: number;
  emoji?: number;
  impact?: number;
  effort?: number;
}
export interface IdeaRating {
  id?: string;
  subeventIdeaId?: string;
  userId?: string;
  impact?: number;
  effort?: number;
  emoji?: number;
  isImpactRated?: boolean;
  isEffortRated?: boolean;
  avgRates?: AvgRates;
}

export interface IIdea {
  id?: string;
  status?: string;
  mwSubeventId: string;
  mwEventId?: string;
  mwSubeventName?: string;
  mwEventName?: string;
  userId?: string;
  title: string;
  text: string;
  avoidOrAchieve: string;
  ideaRating?: IdeaRating[];
  comments?: IComments[];
  createdAt?: Date;
  filePath?: string;
  fileName?: string;
  isAnonymous?: boolean;
  isAnon?: boolean;
  showPopOver?: boolean;
}

export interface IComments {
  id?: string;
  mwSubeventId?: string;
  subeventIdeaId?: string;
  userId?: string;
  commentId?: string;
  postId?: string;
  planStatusId?: string;
  comments?: IComments[] | null;
  createdAt?: Date;
  text: string;
  imagePath?: string;
  userName?: string;
  userImageHasCompression?: boolean;
  filePath?: string;
  isAnon?: boolean;
  fileName?: string;
  showPopOver?: boolean;
  menteeId?: string;
  focusAreaId?: string;
  sprintId?: string;
}

export interface PeopleRatings {
  eventName: string;
  startDate: Date;
  filePath: string;
  engagement: number;
  sentiment: number;
  ideasGenerated: number;
  userCount: number;
  peoples: UserInfo[];
}
export interface EngagementInfo {
  id: string;
  value: number;
}

export interface PathContainer {
  filePath: string;
}

export interface CollaborationInfo {
  id: string;
  name: string;
  usersCount: number;
  ideasCount: number;
  commentsCount: number;
}

export interface EventMemberDto {
  userId: string;
  fullName: string;
  imagePath: string;
  hasCompression: boolean;
}

export type EventMemberDictionary = Record<number, EventMemberDto[]>;

export interface EventMembersDto {
  notLoginedUsers: EventMemberDto[];
  notVisitedUsers: EventMemberDto[];
  finishedUsers: EventMemberDto[];
  inProgressUsers: EventMemberDictionary;
}

const { state, config } = createState(
  withEntities<IMWEvent>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'posts', state, config });
export const trackPostRequestsStatus = createRequestsStatusOperator(store);
export const skipWhilePostsCached = createRequestsCacheOperator(store);

export const MWEventsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class MWEventsRepository extends BaseRepository<IMWEvent> {
  name = store.name;

  posts$ = store.pipe(selectAllEntities());
  post = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: IMWEvent['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  setPosts(posts: IMWEvent[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upsertPost(post: IMWEvent) {
    store.update(
      updateRequestCache(post.id),
      upsertEntities([post]),
      updateRequestsStatus([post.id], 'success')
    );
  }

  constructor() {
    super('apvs', MWEventsSortOptions);
  }
}

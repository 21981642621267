import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TenantsListPageDto } from 'app/api/models/tenant/tenantsListPageDto';
import { SortProperty } from 'app/interfaces/sort-property';
import { AuthRepository } from 'app/state/auth.repository';
import { IConfirm, Tenant } from 'app/state/tenants.repository';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss'],
})
export class TenantListComponent implements OnInit {
  @Input() innerWidth = 0;
  @Input() set tenants(items: TenantsListPageDto[]) {
    this.initTenants = items;
    this.filteredTenants = items;
  }
  @Input() isDeleteDisabled = true;
  @Output() delete = new EventEmitter<string>();
  @Output() clone = new EventEmitter<IConfirm>();
  deleteConfirmation: TenantsListPageDto | null = null;
  cloneConfirmation: TenantsListPageDto | null = null;
  initTenants: TenantsListPageDto[] = [];
  filteredTenants: TenantsListPageDto[] = [];

  sortProps: SortProperty[] = [
    {
      target: 'name',
      classes: ['col-lg-4', 'col-7', 'd-flex', 'psm0'],
      isAscending: true,
    },
    {
      target: 'createdAt',
      label: 'created',
      classes: ['col-5', 'show-on-lg'],
    },
  ];

  constructor(public auth: AuthRepository) {}

  ngOnInit(): void {}

  handleDeleteClick(tenant: TenantsListPageDto) {
    if (!this.isDeleteDisabled) {
      this.delete.emit(tenant.id);
    }
  }
}

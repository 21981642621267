import { Injectable } from '@angular/core';
import { Store, createState } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectEntity,
  upsertEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { AttachedLink, BaseRepository } from './abstract/base.repository';
import { SortOption } from 'app/modules/shared/pipes/sort.pipe';
import { IComments } from './mwevent.repository';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';

export interface ToolBox {
  id: string;
  title: string;
  content: string;
  comments: IComments[];
  createdAt: Date;
  createdById?: string;
  updatedById?: string;
  userId?: string;
  video?: string;
  previewImage?: string;
  userImagePath?: string;
  hasCompression?: boolean;
  userName?: string;
  isRead?: boolean;
  userGroupIds?: string[];
  documents?: Partial<DocumentSubmit>[];
  documentIds?: string[];
  showFrom?: Date;
  showTo?: Date;
  attachedLinks?: Partial<AttachedLink>[];
  toolboxAreaId?: string;
  toolboxAreaName?: string;
}

const { state, config } = createState(
  withEntities<ToolBox>(),
  withRequestsStatus(),
  withRequestsCache()
);

const store = new Store({ name: 'toolboxes', state, config });
export const tracktoolboxRequestsStatus = createRequestsStatusOperator(store);
export const skipWhiletoolboxsCached = createRequestsCacheOperator(store);

export const toolboxSortOptions: SortOption[] = [
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class ToolBoxesRepository extends BaseRepository<ToolBox> {
  name = store.name;

  toolboxs$ = store.pipe(selectAllEntities());
  toolbox = (id: string) => store.pipe(selectEntity(id));
  status = (id: string) =>
    store.pipe(selectRequestStatus(id, { groupKey: store.name }));
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  isLoadingOne$ = (id: ToolBox['id']) =>
    store.pipe(
      selectRequestStatus(id),
      map((x) => x.value === 'pending')
    );

  settoolboxs(toolboxs: ToolBox[]) {
    store.update(
      updateRequestCache(store.name),
      upsertEntities(toolboxs),
      updateRequestsStatus([store.name], 'success')
    );
  }

  upserttoolbox(toolbox: ToolBox) {
    store.update(
      updateRequestCache(toolbox.id),
      upsertEntities([toolbox]),
      updateRequestsStatus([toolbox.id], 'success')
    );
  }

  constructor() {
    super('toolboxs', toolboxSortOptions);
  }
}

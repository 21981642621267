import { Component, OnInit } from '@angular/core';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import { AuthRepository } from '../../../../state/auth.repository';
import { TenantsService } from '../../../../state/tenants.service';
import { GeneralDataRepository } from 'app/helpers/repository/general-data.repository';
import { GeneralAppDataDto } from 'app/api/models/auth/sideBarInfoDto';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor(
    public tenantservice: TenantsService,
    public authRepo: AuthRepository,
    public env: EnvState,
    public generalDataRepo: GeneralDataRepository
  ) {}

  DEFAULT_LOGO = '/assets/img/header_logo.png';

  imgHeight: number = 50;
  logoWasSet: boolean = false;
  imagesrc: string = this.DEFAULT_LOGO;

  generalAppData: GeneralAppDataDto = new GeneralAppDataDto();

  ngOnInit(): void {
    this.generalDataRepo.allGeneralData$.subscribe((data) => {
      this.generalAppData = data;
      this.imagesrc = this.generalAppData.imageLogosrc;
    });
    this.setNyboligLogo();
  }

  public setNyboligLogo() {
    var tenantId = localStorage.getItem('activeTenantId');
    if (tenantId == '36bab6dd-009f-47c5-3128-08dbb9a5dfd9') {
      this.imagesrc = '/assets/img/nybolig.svg';
      this.imgHeight = 100;
    }
  }
}

<form [formGroup]="form" enctype="multipart/form-data">
    <div class="form-group form-floating mb-3">
      <div class="form-floating mb-3">
        <div class="inpt-title">{{ "title" | transloco }}</div>
        <input
          formControlName="preview"
          type="text"
          class="inpt"
          placeholder="{{ 'title' | transloco }}"
          autocomplete="off"
        />
      </div>
      <div class="form-floating mb-3">
        <div class="inpt-title">{{ "body" | transloco }}</div>
        <input
          formControlName="body"
          type="text"
          class="inpt"
          placeholder="{{ 'body' | transloco }}"
          autocomplete="off"
        />
      </div>
    </div>
  </form>
  
  <div class="w-100 d-flex justify-content-end mt-4">
    <button (click)="onSubmit()" class="ms-2 w-100 btn btn-orange">
      {{ "save" | transloco }}
    </button>
  </div>
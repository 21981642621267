import { Component, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthRepository } from '../../../../state/auth.repository';

import {
  IMarketEntity,
  MarketplaceRepository,
} from '../../../../state/marketplace.repository';
import { MWEventService } from '../../../../state/mwevent.service';
import { MarketplaceService } from 'app/state/marketpalce.service';

@Component({
  selector: 'app-market-card',
  templateUrl: './market-card.component.html',
  styleUrls: ['./market-card.component.scss'],
})
export class MarketCardComponent {
  @Input() market: IMarketEntity | null = null;
  @Input() DEFAULT_MARKET: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private repo: MarketplaceRepository,
    private mwEventService: MWEventService,
    private service: MarketplaceService,
    public authRepo: AuthRepository
  ) {}
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthRepository, UserRoles } from 'app/state/auth.repository';
import { TenantsRepository } from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';
import {
  errorInterface,
  ImportedUser,
  User,
  userSortOptions,
  UsersRepository,
} from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
import { dA } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss'],
})
export class UsersListPageComponent implements OnInit {
  sortOptions = userSortOptions;
  innerWidth = 0;
  searchFilter: string = '';
  submitErrors: string[] | null = null;
  hasErrors: boolean = false;
  waitingForImportResponse$ = false;
  users: Array<User> = [];
  filteredUsers: Array<User> = [];
  importFactory = (file: File) => this.service.importUsers(file);
  exportFactory = () => this.service.export();

  constructor(
    public tenantRepo: TenantsRepository,
    public repo: UsersRepository,
    public service: UsersService,
    public auth: AuthRepository
  ) {}

  activeTenant = localStorage.getItem('activeTenantId');

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getAllUsers();

    this.innerWidth = window.innerWidth;
  }

  getAllUsers() {
    this.service.loadAllUsers().subscribe((data) => {
      this.users = data;
      this.filteredUsers = data;
    });
  }

  showError(error: errorInterface) {
    this.hasErrors = error.errorState;
    this.submitErrors = [error.errorMessage];
  }

  handleDeleteClick(id: string) {
    this.service.delete(id).subscribe(() => {
      this.getAllUsers();
    });
  }

  handleRestoreClick(id: string) {
    this.service.restore(id).subscribe(() => {
      this.getAllUsers();
    });
  }

  submitImport(importedUsers: ImportedUser[]) {
    this.waitingForImportResponse$ = true;
    this.service.confirmUsersImport(importedUsers).subscribe((r) => {
      this.waitingForImportResponse$ = false;
      this.service.reloadPage(this.searchFilter).subscribe();
    });
  }
}

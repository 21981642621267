import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IToolboxArea } from 'app/state/toolboxarea.repository';
import { ToolboxAreaService } from 'app/state/toolboxarea.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { UserGroup } from '../../../../state/usergroup.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-toolbox-area-form',
  templateUrl: './toolbox-area-form.component.html',
  styleUrls: ['./toolbox-area-form.component.scss'],
})
export class ToolboxAreaFormComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  _editedFocusArea: Partial<IToolboxArea> | undefined;
  submitErrors: string[] | null = null;

  @Input() loading: boolean = false;
  @Input() set editedArea(value: Partial<IToolboxArea> | undefined) {
    if (value) {
      this.editForm = this.formBuilder.group({
        name: [value.name, Validators.required],
        isActive: [value.isActive],
        userGroupIds: [value.userGroupIds],
      });
      this._editedFocusArea = value;
    }
    this.loading = false;
  }
  groupsOptions: DatalistOption[] | null = null;

  @Input() set groups(value: UserGroup[] | null) {
    if (!value) {
      this.groupsOptions = null;
    } else {
      this.groupsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: ToolboxAreaService,
    private router: Router,
    private location: Location
  ) {}
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      userGroupIds: [],
      isActive: [true],
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const name = this.editForm.value?.name.toString();
    const isActive = !!this.editForm.value?.isActive;
    const userGroupIds = this.editForm.value?.userGroupIds;

    this.submitErrors = null;
    let updateResult: Observable<IToolboxArea> | undefined;

    if (this._editedFocusArea?.id)
      updateResult = this.service.updateWithQueryId(this._editedFocusArea.id, {
        name,
        isActive,
        userGroupIds,
      });
    else updateResult = this.service.add({ name, isActive, userGroupIds });

    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => {
          this.submitErrors = data;
        },
      });
    }
  }
  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}

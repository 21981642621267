export const SIDEBAR_MAIN_ITEMS = {
  feed: {
    title: 'feed',
    routerLink: '/feed',
    svgPath: 'assets/icons/sidebar/feed.svg',
  },

  homeMain: {
    title: 'home',
    routerLink: '/home',
    svgPath: 'assets/icons/sidebar/home.svg',
  },

  newsMain: {
    title: 'news',
    routerLink: '/posts',
    svgPath: 'assets/icons/sidebar/news.svg',
  },

  toolBoxMain: {
    title: 'toolbox',
    routerLink: '/toolbox',
    svgPath: 'assets/icons/sidebar/toolbox.svg',
  },

  notificationsMain: {
    title: 'notifications',
    routerLink: '/notifications',
    svgPath: 'assets/icons/sidebar/notifications.svg',
  },

  calendarMain: {
    title: 'calendar',
    routerLink: '/calendar',
    svgPath: 'assets/icons/sidebar/calendar.svg',
  },

  outOfOfficeMain: {
    title: 'outOfOffice',
    routerLink: '/out-of-office-status',
    svgPath: 'assets/icons/calendar-minus-white.svg',
  },

  timelogsMain: {
    title: 'timelogs',
    routerLink: '/timelogs',
    svgPath: 'assets/icons/sidebar/timelogs.svg',
  },

  colaborationsMain: {
    title: 'collaborations',
    routerLink: '/mwevents',
    svgPath: 'assets/icons/sidebar/colaborations.svg',
  },

  whistleblowMain: {
    title: 'whistleblow',
    routerLink: '/whistleblow',
    svgPath: 'assets/icons/sidebar/whistleblow.svg',
  },

  messagesMain: {
    title: 'messages',
    routerLink: '/messages',
    svgPath: 'assets/icons/sidebar/messages.svg',
  },

  mapMain: {
    title: 'map',
    routerLink: '/maps',
    svgPath: 'assets/icons/sidebar/map.svg',
  },

  apvMain: {
    title: 'apv',
    routerLink: '/apv',
    svgPath: 'assets/icons/sidebar/apv.svg',
  },

  personalplanMain: {
    title: 'personalplan',
    routerLink: '/personalplan',
    svgPath: 'assets/icons/sidebar-icon-personalplan.svg',
  },

  personalcoachMain: {
    title: 'coach',
    routerLink: '/personalcoach',
    svgPath: 'assets/icons/sidebar-icon-personalplan.svg',
  },

  receivedemailsMain: {
    title: 'notifications',
    routerLink: '/notifications/received',
    svgPath: 'assets/icons/email.svg',
  },

  onboardingMain: {
    title: 'onboarding',
    routerLink: '/onboarding',
    svgPath: 'assets/icons/sidebar/onboarding.svg',
  },
};

export const SIDEBAR_IGNITE_ITEMS = {
  colaborations: {
    title: 'collaborations',
    routerLink: '/mwevents/admin',
    svgPath: 'assets/icons/sidebar/colaborations.svg',
  },

  marketplace: {
    title: 'marketplace',
    routerLink: '/marketplace/public',
    svgPath: 'assets/icons/sidebar/marketplace.svg',
  },

  billing: {
    title: 'billing',
    routerLink: '/marketplace/logs/buy',
    svgPath: 'assets/icons/sidebar/billing.svg',
  },
  tenants: {
    title: 'tenants',
    routerLink: '/tenants',
    svgPath: 'assets/icons/sidebar/tenants.svg',
  },

  apvAdmin: {
    title: 'apv',
    routerLink: '/admin/apv',
    svgPath: 'assets/icons/sidebar/apv.svg',
  },

  apvAnswers: {
    title: 'apvAnswers',
    routerLink: '/admin/apv-answers',
    svgPath: 'assets/icons/sidebar/apv.svg',
  },

  documents: {
    title: 'documents',
    routerLink: '/admin/documents',
    svgPath: 'assets/icons/sidebar/documents.svg',
  },

  newsAdmin: {
    title: 'news',
    routerLink: '/admin/posts',
    svgPath: 'assets/icons/sidebar/news.svg',
  },

  toolBoxAdmin: {
    title: 'toolbox',
    routerLink: '/admin/toolbox',
    svgPath: 'assets/icons/sidebar/toolbox.svg',
  },

  calendarAdmin: {
    title: 'calendar',
    routerLink: '/calendar/admin',
    svgPath: 'assets/icons/sidebar/calendar.svg',
  },

  timelogsAdmin: {
    title: 'timelogs',
    routerLink: '/admin/timelogs',
    svgPath: 'assets/icons/sidebar/timelogs.svg',
  },
  timelogsOverviewAdmin: {
    title: 'timeLogsOverview',
    routerLink: '/admin/overview/timelogs',
    svgPath: 'assets/icons/sidebar/timelogs.svg',
  },

  whistleblowAdmin: {
    title: 'whistleblow',
    routerLink: '/admin/whistleblow',
    svgPath: 'assets/icons/sidebar/whistleblow.svg',
  },

  messagesAdmin: {
    title: 'messages',
    routerLink: '/admin/messages',
    svgPath: 'assets/icons/sidebar/messages.svg',
  },

  clients: {
    title: 'clients',
    routerLink: '/admin/clients',
    svgPath: 'assets/icons/sidebar/map.svg',
  },

  emails: {
    title: 'email',
    routerLink: '/notifications/admin',
    svgPath: 'assets/icons/email.svg',
  },
  onboarding: {
    title: 'onboarding',
    routerLink: '/onboarding/admin',
    svgPath: 'assets/icons/sidebar/onboarding.svg',
  },
};

export const SIDEBAR_ADMIN_ITEMS = {
  tenants: {
    title: 'tenants',
    routerLink: '/tenants',
    svgPath: 'assets/icons/sidebar/tenants.svg',
  },

  userGroups: {
    title: 'userGroup',
    routerLink: '/usergroups/admin',
    svgPath: 'assets/icons/icon-usergroup.svg',
  },

  users: {
    title: 'users',
    routerLink: '/users',
    svgPath: 'assets/icons/sidebar/people.svg',
  },

  reports: {
    title: 'reports',
    routerLink: '/reports',
    svgPath: 'assets/icons/sidebar/reports.svg',
  },
};

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClearCacheService {
  wasCleared: boolean = false;
  constructor() {}

  clearServiceWorkerCache(): void {
    console.log('clearing service worker chache');
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
    caches.delete('*');
    // caches
    //   .keys()
    //   .then(async (cacheNames) => {
    //     cacheNames.forEach((cacheName) => {
    //       caches
    //         .delete(cacheName)
    //         .then(() => {})
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     });
    //     window.sessionStorage.clear();
    //     window.localStorage.clear();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    localStorage.setItem('service_worker_chache_was_cleaned', 'true');
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IDocument } from '../../../../state/document.repository';

import { Observable } from 'rxjs';
import { DocumentsService } from '../../../../state/document.service';
import { Location } from '@angular/common';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { DatalistOption } from 'app/state/abstract/base.repository';
@Component({
  selector: 'app-document-post',
  templateUrl: './document-post.component.html',
  styleUrls: ['./document-post.component.scss'],
})
export class DocumentPostComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,

    private service: DocumentsService,
    private location: Location,
    public documentypesRepo: DocumentTypesRepository,
    public documenttypesService: DocumentTypesService
  ) {}

  editForm: UntypedFormBuilder | any;
  file: File | null = null;
  @Input() editedPost: Partial<IDocument> | null = null;
  submitErrors: string[] | null = null;
  ParentFolderOptions: DatalistOption[] | null = null;
  DocumentTypeOptions: DatalistOption[] | null = null;

  //@Input() set folders(value: IFolder[] | null) {
  //  if (!value) {
  //    this.ParentFolderOptions = null;
  //  } else {
  //    this.ParentFolderOptions = value.map(x => ({
  //      value: x.id,
  //      label: x.name
  //    }));
  //  }
  //}

  @Input() set documenttypes(value: IDocumentType[] | null) {
    if (!value) {
      this.DocumentTypeOptions = null;
    } else {
      this.DocumentTypeOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  ngOnInit() {
    this.editForm = this.formBuilder.group({
      title: [this.editedPost?.title, Validators.required],
      documentTypeId: [this.editedPost?.documentTypeId, Validators.required],
      //folderId: [this.editedPost?.folderId, Validators.required],
      document: [this.editedPost?.document, Validators.required],
    });
    //this.foldersService.loadAll().subscribe(x => { this.folders = x });
    this.documenttypesService.loadNonSys().subscribe((x) => {
      this.documenttypes = x.data;
    });
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.editForm) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.editForm.value.document = this.file;
      }
    }
  }

  onSubmit() {
    const title = this.editForm.value?.title?.toString();
    const documentTypeId = this.editForm.value?.documentTypeId;
    //const folderId = this.editForm.value?.folderId;
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.submitErrors = null;
    let updateResult: Observable<IDocument> | undefined;

    this.service
      .postDocument({ title, documentTypeId /*, folderId */ })
      .subscribe((x) => {
        const id = x.id;
        updateResult = this.service.add({
          id,
          title,
          documentTypeId,
          /* folderId, */ document: this.file,
        });
        if (updateResult) {
          updateResult.subscribe({
            complete: () => {
              this.location.back();
            },
            error: (data) => (this.submitErrors = data),
          });
        }
      });
  }
  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}

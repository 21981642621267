<div class="d-flex import-export-group" role="group">
  <button
    *ngIf="importFactory"
    type="button"
    class="btn btn-lg orange-dark text-white"
    [disabled]="isImporting"
    (click)="fileImport.click()"
  >
    <i *ngIf="!isImporting" class="bi bi-file-earmark-arrow-up me-1"></i>
    <app-spinner *ngIf="isImporting"></app-spinner>
    <ng-container class="text-btn-add">{{ "import" | transloco }}</ng-container>
  </button>
  <button
    *ngIf="exportFactory"
    type="button"
    class="btn btn-lg orange-dark text-white"
    [disabled]="isExporting"
    (click)="export()"
  >
    <i *ngIf="!isExporting" class="bi bi-file-earmark-arrow-down me-1"></i>
    <app-spinner *ngIf="isExporting"></app-spinner>
    <ng-container class="text-btn-add"
      >{{ "export" | transloco }}
    </ng-container>
  </button>
</div>
<a #importExportLink class="d-none"></a>
<input
  #fileImport
  type="file"
  class="d-none"
  (change)="import($event)"
  [attr.accept]="filenameExtension"
/>

<app-import-users-modal
  [importedUsersSet]="importedUsers"
  (cancel)="resetImport()"
  (submitImport)="submitImport.emit($event)"
  [waitingForImportResponseSet]="waitingForImportResponse$"
></app-import-users-modal>

import { Injectable } from '@angular/core';
import { MarketCategory } from './marketplace.repository';
import { BaseRepository } from './abstract/base.repository';
import { SortOption } from '../modules/shared/pipes/sort.pipe';

export const MarketCategoriesSortOptions: SortOption[] = [
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class MarketCategoriesRepository extends BaseRepository<MarketCategory> {
  constructor() {
    super('MarketSortOptions', MarketCategoriesSortOptions);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeriodSelectComponent } from './components/period-select/period-select.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ImageVideoComponentComponent } from './components/image-video-component/image-video-component.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../shared/shared.module';
import { AttachDocumentsComponent } from './components/attach-documents/attach-documents.component';
import { AttachLinksComponent } from './components/attach-links/attach-links.component';
import { AttachDocumentsAsFormComponent } from './components/attach-documents-as-form/attach-documents-as-form.component';
import { AttachLinksAsFormComponent } from './components/attach-links-as-form/attach-links-as-form.component';
import { BlobFileUploaderComponent } from './components/blob-file-uploader/blob-file-uploader.component';

@NgModule({
  declarations: [
    PeriodSelectComponent,
    ImageVideoComponentComponent,
    FileUploaderComponent,
    AttachDocumentsComponent,
    AttachLinksComponent,
    AttachDocumentsAsFormComponent,
    AttachLinksAsFormComponent,
    BlobFileUploaderComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressBarModule,
    MatListModule,
    NgSelectModule,
    ModalModule.forRoot(),
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    TranslocoRootModule,
  ],
  exports: [
    AttachDocumentsComponent,
    AttachDocumentsAsFormComponent,
    AttachLinksAsFormComponent,
    PeriodSelectComponent,
    ImageVideoComponentComponent,
    FileUploaderComponent,
    BlobFileUploaderComponent,
    AttachLinksComponent,
  ],
})
export class BaseModule {}

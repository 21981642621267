export class EnvState {
  name = 'AppName';
  apiUrl = '';
  features = {
    news: false,
    offlineChanges: false,
    whistleblow: false,
    mail: false,
    calendar: false,
    apv: false,
    mwevents: false,
    timelogs: false,
    notifications: false,
    marketplace: false,
    usergroup: false,
    toolBox: false,
    personalPlan: false,
    outOfOfficeStatus: false
  };
  locale = 'en-gb';
}

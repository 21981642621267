import { HttpClient } from '@angular/common/http';
import { PaginationData } from '@ngneat/elf-pagination';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { SortState } from 'app/modules/shared/pipes/sort.pipe';
import { BaseRepository, DatalistOption } from './base.repository';
import { DataService } from 'app/shared/data.service';
export const DEFAULT_ENTITIES_PER_PAGE = 10;

export class BaseService<T extends { id: string }> {
  constructor(
    private api: string,
    protected http: HttpClient,
    protected repo: BaseRepository<T>,
    public dataService?: DataService
  ) {}

  loadWithoutPagination(includeAttributes: string[] = []): Observable<T[]> {
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http
      .get<Observable<T[]>>(`${this.api}?${query.join('&')}`)
      .pipe(this.repo.track(), this.repo.skipWhileCached());
  }

  load(
    includeAttributes: string[] = []
  ): Observable<PaginationData & { data: T[] }> {
    this.repo.clear();
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http
      .get<PaginationData & { data: T[] }>(`${this.api}?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.set(res.data)),
        this.repo.track()
        //this.repo.skipWhileCached()
      );
  }

  loadForDatalist() {
    let tenantIdFromLocaleStorage = localStorage.getItem('activeTenantId');
    const query = [];
    if (tenantIdFromLocaleStorage) {
      query.push(`activeTenantId=${tenantIdFromLocaleStorage}`);
    }

    return this.http.get<DatalistOption[]>(
      `${this.api}/forDatalist?${query.join('&')}`
    );
  }

  loadDeleted(
    includeAttributes: string[] = []
  ): Observable<PaginationData & { data: T[] }> {
    const query = [`includeAttributes=${includeAttributes}`];

    return this.http
      .get<PaginationData & { data: T[] }>(
        `${this.api}/deleted?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.setDeleted(res.data)),
        this.repo.trackDeleted()
        //this.repo.skipWhileCachedDeleted()
      );
  }

  loadFilterPage(
    search: string = '',
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = []
  ): Observable<PaginationData & { data: T[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `includeAttributes=${includeAttributes}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: T[] }>(`${this.api}?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    includeAttributes: string[] = []
  ): Observable<PaginationData & { data: T[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `includeAttributes=${includeAttributes}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: T[] }>(`${this.api}?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }
  unSubscribeFromDS() {
    this.dataService?.activeUserGroups$?.unsubscribe();
  }
  sort(sortBy: SortState, search?: string, includeAttributes: string[] = []) {
    this.repo.setSort(sortBy);
    return this.reloadPage(
      search,
      DEFAULT_ENTITIES_PER_PAGE,
      includeAttributes
    );
  }

  reloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = []
  ): Observable<(PaginationData & { data: T[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(
        data.currentPage,
        data.perPage,
        search,
        includeAttributes
      );
    }
    return this.loadPage(1, defaultTake, search, includeAttributes);
  }

  searchReloadPage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = []
  ): Observable<(PaginationData & { data: T[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(1, data.perPage, search, includeAttributes);
    }
    return this.loadPage(1, defaultTake, search, includeAttributes);
  }

  protected tapReloadPage<R>() {
    return switchMap((sourceResponse: R) =>
      combineLatest([of(sourceResponse), this.reloadPage()]).pipe(
        map(([res, _]) => res)
      )
    );
  }

  loadOneForAdmin(id: string, includeAttributes: string[] = []): Observable<T> {
    const query = [`includeAttributes=${includeAttributes}`];

    return this.http.get<T>(`${this.api}/${id}?${query.join('&')}`).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id),
      this.repo.skipWhileCached(id)
    );
  }

  loadOneWithQueryId(id: string) {
    const query = [`id=${id}`];
    return this.http.get<T>(`${this.api}/loadone?${query.join('&')}`).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id),
      this.repo.skipWhileCached(id)
    );
  }

  add(model: Partial<T>, includeAttributes: string[] = []): Observable<T> {
    const query = [`includeAttributes=${includeAttributes}`];

    return this.http.post<T>(`${this.api}?${query.join('&')}`, model).pipe(
      tap((res) => this.repo.upsert(res)),
      this.tapReloadPage(),
      this.repo.track('add')
    );
  }

  update(
    id: string,
    model: Partial<T>,
    includeAttributes: string[] = []
  ): Observable<T> {
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http
      .patch<T>(`${this.api}/${id}?${query.join('&')}`, model)
      .pipe(
        tap((res) => this.repo.upsert(res)),
        this.repo.trackOne(id)
      );
  }

  updateWithQueryId(id: string, model: Partial<T>): Observable<T> {
    const query = [`id=${id}`];
    return this.http.patch<T>(`${this.api}?${query.join('&')}`, model).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id)
    );
  }

  deleteWithQueryId(id: string): Observable<void> {
    const query = [`id=${id}`];
    return this.http.delete<void>(`${this.api}?${query.join('&')}`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(),
      this.repo.trackOne(id)
    );
  }

  delete(id: string, includeAttributes: string[] = []): Observable<void> {
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http.delete<void>(`${this.api}/${id}?${query.join('&')}`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(),
      this.repo.trackOne(id)
    );
  }

  export(): Observable<string> {
    var tenantIdFromLocaleStorage = localStorage.getItem('activeTenantId');
    const query = [];
    query.push(`activeTenantId=${tenantIdFromLocaleStorage}`);
    return this.http
      .get<Blob>(`${this.api}/export/csv?${query.join('&')}`, {
        responseType: 'blob' as any,
      })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }

  import(file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post<any>(`${this.api}/csv`, formData)
      .pipe(this.tapReloadPage());
  }
}

<div class="row justify-content-center mt-3">
  <h2 class="sm-del page-title col-sm-4 col-12">
    {{ "marketplace" | transloco }}
  </h2>
  <!--<div
    class="col-sm-6 col-8 mb-sm-5 d-flex justify-content-sm-end justify-content-center"
  >
    <div class="butsect d-flex justify-content-center align-items-center">
      <a class="btn button butActive me-1">{{ "public" | transloco }}</a>
      <a href="admin/marketplace" class="btn button">{{
        "private" | transloco
      }}</a>
    </div>
  </div>-->
  <div class="row col-12 col-lg-11 col-xl-10 pb-3 pt-sm-3 ps-0 pe-0">
    <div
      class="row p-0 m-0 mb-sm-4 mb-5 d-flex justify-content"
      *ngVar="repo.all$ | async as markets"
    >
      <ng-container *ngFor="let item of categoryRepo.all$ | async">
        <h2 class="mt-4 ms-2 mb-3" *ngIf="getQuantity(markets, item) > 0">
          {{ "category" | transloco }}
          <span class="ms-2">[{{ item?.name }}] </span>
        </h2>
        <ng-container
          *ngFor="let market of getMarkets(markets, item); let i = index"
        >
          <div
            class="col-4"
            *ngIf="
              market.marketCategoryId === item.id &&
              (i < 6 || isInShow(item.id))
            "
          >
            <app-market-card
              [market]="market"
              [DEFAULT_MARKET]="DEFAULT_MARKET"
            >
            </app-market-card>
          </div>
        </ng-container>
        <div
          *ngIf="getQuantity(markets, item) > 6 && !isInShow(item.id)"
          (click)="showAllIds.push(item.id)"
          class="btn btn-outline-secondary slide-down w-100 mt-3"
        >
          <h2>{{ "showAll" | transloco }}</h2>
        </div>
        <div
          #scrollTarget
          [hidden]="!(getQuantity(markets, item) > 6 && isInShow(item.id))"
          (click)="remove(item.id)"
          class="btn btn-outline-secondary w-100 mt-3"
        >
          <h2>{{ "hide" | transloco }}</h2>
        </div>
      </ng-container>
    </div>
  </div>
</div>

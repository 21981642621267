import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IToolboxArea } from 'app/state/toolboxarea.repository';
import { ToolboxAreaService } from 'app/state/toolboxarea.service';
import { UserGroup } from '../../../../state/usergroup.repository';
import { UserGroupsService } from '../../../../state/usergroup.service';

@Component({
  selector: 'app-toolbox-area-edit',
  templateUrl: './toolbox-area-edit.component.html',
  styleUrls: ['./toolbox-area-edit.component.scss'],
})
export class ToolboxAreaEditComponent {
  editedArea: IToolboxArea | undefined;
  groups: UserGroup[] = []

  constructor(
    private route: ActivatedRoute,
    private service: ToolboxAreaService,
    private groupsService: UserGroupsService,

  ) {
    this.route.paramMap.subscribe({
      next: (value) => {
        const id = value.get('id')!;
        if (id) {
          groupsService.loadAll().subscribe(x => this.groups = x)

          this.service.loadOneWithQueryId(id).subscribe((x) => {
            this.editedArea = x;
          });
        }
      },
    });
  }
}

<div class="docform">
  <form [formGroup]="docForm" enctype="multipart/form-data">
    <div class="form-group form-floating mb-3">
      <div class="col-12 row mb-3 p-0 m-0 mt-3">
        <div class="rel_container">
          <app-blob-file-uploader
            class="w-100 p-0"
            (FileSubmit)="updateFile($event)"
            [placeholder]="'Select File'"
            [fileType]="'any'"
            [fileTypeValidationString]="'any'"
            [hidePlaceholder]="true"
          ></app-blob-file-uploader>
          <div class="mainfont imgfont ms-1 mb-2">
            <div *ngFor="let doc of getControls(); let i = index">
              <span
                class="pointer"
                (click)="
                  blobService.openFileFromBlob(
                    doc.value.documentPath,
                    doc.value.isFileOnRightBlob
                  )
                "
              >
                {{ blobService.getFileName(doc.value.documentPath) }}
              </span>
              <img
                class="ps-2 cursor-pointer"
                (click)="deleteFile(i)"
                src="assets/img/trash.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

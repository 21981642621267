import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { MarketplaceService } from 'app/state/marketpalce.service';
import {
  IMarketEntity,
  MarketplaceRepository,
  PurchaseTypeOptions,
} from 'app/state/marketplace.repository';
import { IConfirm } from 'app/state/tenants.repository';

@Component({
  selector: 'app-buy-markt-pop-up',
  templateUrl: './buy-markt-pop-up.component.html',
  styleUrls: ['./buy-markt-pop-up.component.scss'],
})
export class BuyMarktPopUpComponent {
  @Output() confirm = new EventEmitter<IConfirm>();
  @Output() cancel = new EventEmitter<void>();
  @Input() buyCandidate: IMarketEntity | null = null;
  @Input() buyCandidateName: string = '';
  Form: UntypedFormBuilder | any;

  @Input() currentTenant: string | null = null;
  purchaseTypeOptions: DatalistOption[] = PurchaseTypeOptions;

  constructor(private formBuilder: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      purchaseType: [this.buyCandidate!.purchaseType, Validators.required],
      title: [this.buyCandidateName, Validators.required],
      id: this.buyCandidate!.id,
      tenantId: this.currentTenant,
    });
  }
  submit() {
    if (this.Form && !this.Form.valid) {
      this.Form.markAllAsTouched();
      return;
    }
    const title = this.Form.value?.title;
    const id = this.Form.value?.id;
    const tenantId = this.Form.value?.tenantId;
    const purchaseType = this.Form.value?.purchaseType;

    this.confirm.emit({
      title,
      id,
      tenantId: tenantId ? tenantId : undefined,
      purchaseType,
    });
  }
  updateValue(control: string, value: any) {
    const controlObject = this.Form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}

<div class="form-group form-floating ps-0 pe-0">
  <div
    [class.text-muted]="disableAll"
    *ngIf="label"
    class="inpt-title ps-0 pe-0"
    [attr.for]="id"
  >
    {{ label }}
  </div>
  <ng-select
    *ngIf="!multiple"
    placeholder="{{ placeholder }}"
    [class.bg-disabled]="disableAll"
    class="ps-0 pe-0"
    [labelForId]="id"
    [multiple]="false"
    [clearable]="clearable"
    [searchable]="searchable"
    [(ngModel)]="activeValue"
    (ngModelChange)="activeValueChange.emit($event)"
  >
    <ng-option *ngFor="let option of options" [value]="option.value">
      <img
        *ngIf="option.imagePath"
        width="18"
        height="18"
        class="rounded-circle mx-1"
        [src]="blobService.getUserImagePath(option.imagePath)"
      />
      <img
        *ngIf="!option.imagePath && forUsers"
        width="16"
        height="16"
        class="rounded-circle mx-1"
        src=" {{ DEFAULT_IMAGE }}  "
      />
      <span>{{
        withTranslations ? (option.label | transloco) : option.label
      }}</span>
      <br />
      <span class="small text-muted">{{ option.sublabel }}</span>
    </ng-option>
  </ng-select>
  <app-multiple-datalist-modal
    *ngIf="multiple"
    [inputOptions]="{options, activeValue}"
    [datalistId]="id"
    [forFilter]="forFilter"
    [forUsers]="forUsers"
    [withTranslations]="withTranslations"
    (emitSelectedOptions)="setActiveValue($event)"
  ></app-multiple-datalist-modal>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import {
  IMWEvent,
  IdeaRating,
  IIdea,
  PathContainer,
  MWEventsRepository,
  IComments,
  ISubeventAnswer,
  PeopleRatings,
  ExportRequest,
  PrioritizedDismised,
  CollaborationInfo,
  EventMembersDto,
  EditCommentIdeaDto,
} from './mwevent.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import { BaseService } from './abstract/base.service';
import { User } from './users.repository';
import { IConfirm } from './tenants.repository';
import { SprintListDto } from 'app/api/models/sprint/sprintListDto';
const API = '/api/mwevents';
const APIIdea = '/api/idea';
const APIComment = '/api/comment';
const APIRating = '/api/rateideas';
const APISubevents = '/api/mwsubevent';
@Injectable({
  providedIn: 'root',
})
export class MWEventService extends BaseService<IMWEvent> {
  constructor(http: HttpClient, repo: MWEventsRepository) {
    super(API, http, repo);
  }

  loadAllForUser() {
    return this.http.get<SprintListDto[]>(`${API}/forUser`);
  }

  loadOneForAdmin(id: string) {
    const query = [`id=${id}`];
    return this.http.get<IMWEvent>(`${API}/loadone?${query.join('&')}`);
  }

  //refactored

  deletePreview(id: string) {
    return this.http.delete(`${API}/deletePreview/${id}`);
  }

  loadAllForAdmin(search?: string) {
    return this.http.get<SprintListDto[]>(`${API}/foradmin`);
  }

  addPreview(event: Partial<IMWEvent>): Observable<string> {
    return this.http.put<string>(`${API}/addPreview`, event);
  }

  exportPDF(request: ExportRequest) {
    return this.http.put<PathContainer>(`${API}/pdf`, request);
  }

  prioritizeDismiss(id: string, action: string) {
    return this.http.put(`${API}/prioritizedDismissed`, {
      id: id,
      status: action,
    });
  }

  removePreviewTag(
    id: string,
    entity: Partial<IMWEvent>
  ): Observable<IMWEvent> {
    return this.http.put<IMWEvent>(`${API}/removePreviewTag/${id}`, entity);
  }

  loadPeopleRatings(id: string) {
    const query = [`id=${id}`];
    return this.http.get<PeopleRatings>(`${API}/people?${query.join('&')}`);
  }

  getIndex(id: string, n: number) {
    const query = [`id=${id}`, `k=${n}`];
    return this.http.put<User>(`${API}/userposition?${query.join('&')}`, {});
  }

  exportEvent(id: string) {
    const query = [`id=${id}`];
    return this.http.get(`${API}/export?${query.join('&')}`);
  }

  postRating(rate: IdeaRating) {
    return this.http.post<IdeaRating>(APIRating, rate);
  }

  clone(entity: IConfirm) {
    return this.http.put<IMWEvent>(`${API}/clone`, entity);
  }

  loadIdeasRating(id: string) {
    const query = [`id=${id}`];
    return this.http.get<PrioritizedDismised>(
      `${API}/ideas?${query.join('&')}`
    );
  }
  getForOptions() {
    return this.http.get<IMWEvent[]>(`${API}/getForOptions`);
  }
  getForDiffTenant(tenantId: string) {
    return this.http.get<IMWEvent[]>(
      `${API}/getForDiffTennant/${tenantId}/WithoutTenant`
    );
  }

  loadIdeas(id: string) {
    return this.http.get<IIdea>(`${APIIdea}/${id}`);
  }

  addIdea(idea: IIdea) {
    const activeTenantId = localStorage.getItem('activeTenantId');
    return this.http.post<IIdea>(
      `${APIIdea}/addIdea?activeTenantId=${activeTenantId}`,
      idea
    );
  }

  updateIdea(id: string, body: EditCommentIdeaDto) {
    const activeTenantId = localStorage.getItem('activeTenantId');
    const query = [`id=${id}`, `activeTenantId=${activeTenantId}`];
    return this.http.put<IComments>(`${API}/editIdea?${query.join('&')}`, body);
  }

  addAnswer(answer: ISubeventAnswer) {
    return this.http.post<ISubeventAnswer>(`${API}/answer`, answer);
  }

  addComment(comment: IComments) {
    const activeTenantId = localStorage.getItem('activeTenantId');
    return this.http.post<IComments>(
      `${APIComment}/addComment?activeTenantId=${activeTenantId}`,
      comment
    );
  }

  updateComment(id: string, body: EditCommentIdeaDto) {
    const activeTenantId = localStorage.getItem('activeTenantId');
    const query = [`id=${id}`, `activeTenantId=${activeTenantId}`];
    return this.http.put<IComments>(
      `${API}/editComment?${query.join('&')}`,
      body
    );
  }

  setClosed(id: string) {
    return this.http.get<string>(`${API}/close/${id}`);
  }

  load() {
    return this.http.get<PaginationData & { data: IMWEvent[] }>(API);
  }

  add(post: Partial<IMWEvent>): Observable<IMWEvent> {
    return this.http.post<IMWEvent>(API, post);
  }

  update(id: string, post: Partial<IMWEvent>): any {
    const query = [`id=${id}`];
    return this.http.put<IMWEvent>(`${API}?${query.join('&')}`, post);
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const req = new HttpRequest('POST', `${API}/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${API}/files`);
  }

  deleteIdea(id: string): Observable<void> {
    const query = [`id=${id}`];
    return this.http.delete<void>(`${API}/idea?${query.join('&')}`);
  }

  deleteComment(id: string): Observable<void> {
    const query = [`id=${id}`];
    return this.http.delete<void>(`${API}/comment?${query.join('&')}`);
  }

  loadInfo(id: string) {
    return this.http.get<CollaborationInfo[]>(`${API}/tenantinfo/${id}`);
  }

  getEventMembers(id: string) {
    const query = [`id=${id}`];
    return this.http.get<EventMembersDto>(
      `${API}/eventmembers?${query.join('&')}`
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginationData } from '@ngneat/elf-pagination';

import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { IToolboxArea, ToolboxAreaRepository } from './toolboxarea.repository';
import { Observable, tap } from 'rxjs';
import { SortState } from 'app/modules/shared/pipes/sort.pipe';
import { ToolboxAreaForListDto } from 'app/api/models/toolbox/toolboxAreaForListDto';

const API = '/api/toolboxareas';

@Injectable({ providedIn: 'root' })
export class ToolboxAreaService extends BaseService<IToolboxArea> {
  constructor(http: HttpClient, repo: ToolboxAreaRepository) {
    super(API, http, repo);
  }

  add(data: Partial<IToolboxArea>) {
    return this.http.post<IToolboxArea>(`${API}`, data);
  }

  loadAll() {
    return this.http.get<ToolboxAreaForListDto[]>(`${API}/all`);
  }

  loadMenuItems() {
    return this.http.get<IToolboxArea[]>(`${API}/menuitems`);
  }

  load() {
    return this.http.get<PaginationData & { data: IToolboxArea[] }>(API);
  }

  loadOneForAdmin(id: string) {
    return this.http.get<IToolboxArea>(`${API}/${id}`);
  }

  loadOneWithQueryId(id: string) {
    const query = [`id=${id}`];
    return this.http.get<IToolboxArea>(`${API}/loadone?${query.join('&')}`);
  }

  update(id: string, entity: Partial<IToolboxArea>) {
    return this.http.patch<IToolboxArea>(`${API}/${id}`, entity);
  }
  sortCustom(
    sortBy: SortState,
    search?: string,
    includeAttributes: string[] = []
  ) {
    this.repo.setSort(sortBy);
    return this.reloadPageCustom(
      search,
      DEFAULT_ENTITIES_PER_PAGE,
      includeAttributes
    );
  }

  loadPageCustom(
    page: number,
    take: number = 10,
    search: string = ''
  ): Observable<PaginationData & { data: IToolboxArea[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: IToolboxArea[] }>(
        `/api/toolboxareas/pagewithtenantfilter?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  reloadPageCustom(
    search: string = '',
    defaultTake: number = 10,
    includeAttributes: string[] = []
  ): Observable<(PaginationData & { data: IToolboxArea[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(data.currentPage, data.perPage, search);
    }
    return this.loadPageCustom(1, defaultTake, search);
  }
}

<h2 class="mb-4" *ngIf="type === 'new'" i18n="Create market label">
  {{ "createMarket" | transloco }}
</h2>
<h2 class="mb-4" *ngIf="type !== 'new'" i18n="Edit market label">
  {{ "editMarket" | transloco }}
</h2>
<div *ngIf="type === 'new' || marketEntity" class="row mb-4">
  <div class="col-12">
    <app-marketplace-form
      [events]="events ?? (mwEventRepo.all$ | async)"
      [categories]="categoriesRepo.all$ | async"
      [editedMarketEntity]="marketEntity"
      (marketEntitySubmit)="createMarketEntity($event)"
    ></app-marketplace-form>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="{{ 'couldNotSubmitTheMarketEntity' | transloco }}"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>

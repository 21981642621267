import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToolBox } from 'app/state/toolbox.repository';
import {
  UserGroupsRepository,
  UserGroup,
} from 'app/state/usergroup.repository';
import { UserGroupsService } from 'app/state/usergroup.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ToolBoxesService } from 'app/api/services/toolbox.service';

@Component({
  selector: 'app-toolboxes-edit',
  templateUrl: './toolboxes-edit.component.html',
  styleUrls: ['./toolboxes-edit.component.scss'],
})
export class ToolboxesEditComponent {
  ToolBox: ToolBox | null = null;
  id: string | null = null;
  submitErrors: string[] | null = null;

  constructor(
    private service: ToolBoxesService,
    private location: Location,
    private route: ActivatedRoute,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository
  ) {}
  groups: UserGroup[] | null = null;
  deletedGroups: UserGroup[] | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id !== 'new') {
          this.loadEntity(this.id);
        }

        this.userGroupsService.loadAll().subscribe((x) => (this.groups = x));
        this.userGroupsService
          .loadAllDeleted()
          .subscribe((x) => (this.deletedGroups = x));
      },
    });
  }

  loadEntity(id: string) {
    this.service
      .loadOneWithQueryId(id, ['userGroups', 'documents', 'attachedLinks'])
      .subscribe((x) => {
        this.ToolBox = x;
      });
  }

  updateToolBox(toolBox: Partial<ToolBox>) {
    this.submitErrors = null;
    let updateResult: Observable<ToolBox> | undefined;
    if (this.id === 'new') {
      updateResult = this.service.add(toolBox);
    } else if (this.id) {
      updateResult = this.service.update(this.id, toolBox);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}

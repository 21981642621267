<h2 class="mb-4" *ngIf="id === 'new'">
  {{ "createToolBox" | transloco }}
</h2>
<h2 class="mb-4" *ngIf="id !== 'new'">
  {{ "editToolBox" | transloco }}
</h2>
<div class="row mb-4" *ngIf="ToolBox || id === 'new'">
  <app-toolboxes-form
    [editedToolBox]="ToolBox"
    (toolBoxSubmit)="updateToolBox($event)"
    [groups]="groups"
    [deletedGroups]="deletedGroups"
  ></app-toolboxes-form>
  <app-error-alert
    title="{{ 'ToolBoxError' | transloco }}"
    [errors]="submitErrors"
  ></app-error-alert>
</div>

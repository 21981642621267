import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository, DatalistOption } from './abstract/base.repository';

export const MarketSortOptions: SortOption[] = [
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
];
export interface IMarketEntity {
  id: string;
  title: string;
  description: string;
  createdAt: Date;
  tenantId: string;
  tenantName?: string;
  mwEventId: string;
  file: File | null;
  imagePath: string;
  marketCategoryId: string;
  purchaseType: string;

  //marketCategory: MarketCategory;
  isFromDifferentTenant: boolean;
}
export const PurchaseTypeOptions: DatalistOption[] = [
  { label: 'Buy Access', value: 'buyAccess' },
  { label: 'Unlimited Access', value: 'unlimitedAccess' },
];
export interface MarketCategory {
  id: string;
  name: string;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class MarketplaceRepository extends BaseRepository<IMarketEntity> {
  constructor() {
    super('MarketSortOptions', MarketSortOptions);
  }
}

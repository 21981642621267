import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { DatalistOption } from 'app/state/abstract/base.repository';

var _appDatalistSelectNextId = 0;

@Component({
  selector: 'app-datalist-select',
  templateUrl: './datalist-select.component.html',
  styleUrls: ['./datalist-select.component.scss'],
})
export class DatalistSelectComponent {
  @Input() multiple = false;
  @Input() disableAll = false;
  @Input() placeholder = '';
  @Input() forUsers = false;
  @Input() forFilter = false;

  @Input() clearable = true;
  @Input() searchable = true;
  @Input() activeValue: any | undefined;
  @Input() withTranslations = false;
  @Output() activeValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() label: string | undefined;
  @Input() options: DatalistOption[] | null = [];
  @Input() optionsDeleted: DatalistOption[] | null = [];
  readonly DEFAULT_IMAGE = 'assets/img/user_picture.png';
  id = `appDatalistSelect_${_appDatalistSelectNextId++}`;

  constructor(public blobService: BlobStorageService) {}

  setActiveValue(e: any) {
    this.activeValue = e;
    this.activeValueChange.emit(this.activeValue);
  }
}

<div class="row page-header d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row">
    <div class="col-6 mt-sm-4 mb-sm-3 p-0">
      <h2 class="page-title">
        {{ "userList" | transloco }}
      </h2>
    </div>
    <div
      *ngIf="(auth.isAnyAdmin$ | async) && innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <app-import-export
        *ngIf="activeTenant && (auth.isTenantAdmin$ | async)"
        class="me-2"
        filenamePrefix="users"
        [importFactory]="importFactory"
        [exportFactory]="exportFactory"
        (outputError)="showError($event)"
        (submitImport)="submitImport($event)"
        [waitingForImportResponseSet]="waitingForImportResponse$"
      ></app-import-export>
      <a routerLink="/users/new" class="btn btn-lg orange-dark text-white">
        <img src="assets/icons/icon-add.svg" alt="Add" />
        <ng-container class="text-btn-add">
          {{ "add" | transloco }}
        </ng-container>
      </a>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search-input
        class="col-auto"
        style="min-width: 50%"
        [items]="users"
        [searchProperties]="['email', 'name', 'surname']"
        (foundItems)="filteredUsers = $event"
      />
    </div>
    <app-import-export
      *ngIf="(auth.isTenantAdmin$ | async) && innerWidth < 1050"
      class="pe-0 mt-2 col-12 d-flex justify-content-end"
      filenamePrefix="users"
      [importFactory]="importFactory"
      [exportFactory]="exportFactory"
      (outputError)="showError($event)"
    ></app-import-export>
    <app-error-alert
      *ngIf="hasErrors"
      class="mt-3 px-0"
      title="{{ 'couldNotImportUsers' | transloco }}"
      [errors]="submitErrors"
    ></app-error-alert>
    <app-users-list
      class="sm-m p-0 mb-2 m-0"
      style="width: 100%"
      [innerWidth]="innerWidth"
      [users]="filteredUsers"
      [isDeleteDisabled]="!(auth.isSuperAdmin$ | async)"
      (pageChange)="service.loadFilterPage(searchFilter, $event).subscribe()"
      (delete)="handleDeleteClick($event)"
      (restore)="handleRestoreClick($event)"
    ></app-users-list>
  </div>
</div>

import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { IMarketEntity } from './marketplace.repository';

export const MarketSortOptions: SortOption[] = [
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
];

@Injectable({ providedIn: 'root' })
export class MarketplacePrivateRepository extends BaseRepository<IMarketEntity> {
  constructor() {
    super('MarketSortOptions', MarketSortOptions);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRepository } from 'app/state/auth.repository';
import { AuthService } from '../../../../state/auth.service';
import { UiRepository } from '../../../../state/ui.repository';
import { EnvState } from '../../../shared/helpers/env-state';
import { GeneralDataRepository } from '../../../../helpers/repository/general-data.repository';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';

@Component({
  selector: 'app-menuuser-card',
  templateUrl: './menuuser-card.component.html',
  styleUrls: ['./menuuser-card.component.scss'],
})
export class MenuUserCardComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';

  @Output() profileClick = new EventEmitter<Event>();

  constructor(
    public repo: AuthRepository,
    private auth: AuthService,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState,
    private sidebarRepo: GeneralDataRepository,
    public blobService: BlobStorageService
  ) {}

  ngOnInit(): void {}

  logout() {
    this.auth.logout().subscribe(() => this.router.navigate(['/signin']));
    return false; // prevent default
  }

  handleClick(event: any) {
    this.profileClick.emit(event);
    this.menuclose();
  }

  menuclose() {
    this.ui.toggleMenuOpened(false);
  }

  unimpersonate() {
    this.auth.unimpersonate().subscribe((x) => {
      localStorage.removeItem('activeTenantId');
      localStorage.setItem('go_reload', 'true');
      this.sidebarRepo.updateAllGeneralData(true);
      this.router.navigate(['/']);
    });
    return false; // prevent default
  }
}

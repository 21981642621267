import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminMarketplaceComponent } from './pages/admin-marketplace/admin-marketplace.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MarketplaceFormComponent } from './components/marketplace-form/marketplace-form.component';
import { MarketplaceCreateComponent } from './pages/marketplace-create/marketplace-create.component';
import { MarketplacePublicPageComponent } from './pages/marketplace-public-page/marketplace-public-page.component';
import { MarketplacePrivatePageComponent } from './pages/marketplace-private-page/marketplace-private-page.component';
import { MarketplaceInfoComponent } from './pages/marketplace-info/marketplace-info.component';
import { MarketCardComponent } from './components/market-card/market-card.component';
import { BuyMarktPopUpComponent } from './components/buy-markt-pop-up/buy-markt-pop-up.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarketplaceLogsBuyComponent } from './pages/marketplace-logs-buy/marketplace-logs-buy.component';
import { MarketplaceLogsSellComponent } from './pages/marketplace-logs-sell/marketplace-logs-sell.component';

import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [
    AdminMarketplaceComponent,
    MarketplaceFormComponent,
    MarketplaceCreateComponent,
    MarketplacePublicPageComponent,
    MarketplacePrivatePageComponent,
    MarketplaceInfoComponent,
    MarketCardComponent,
    MarketplaceLogsBuyComponent,
    MarketplaceLogsSellComponent,
    BuyMarktPopUpComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    TranslocoRootModule,
    ModalModule,
    BrowserAnimationsModule,
  ],
})
export class MarketplaceModule {}

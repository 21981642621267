import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'app/api/services/users.service';
import { AuthService } from 'app/state/auth.service';

@Component({
  selector: 'app-gdpr-confirmation',
  templateUrl: './gdpr-confirmation.component.html',
  styleUrls: ['./gdpr-confirmation.component.scss'],
})
export class GdprConfirmationComponent implements OnInit {
  gdprConfirmed: boolean = false;
  redirectGDPRUrl: string = 'https://igntr.me/gdpr-english/';

  IgnitePrivacyAndCookieConfirmation: string =
    'Yes, I accept the Igntr Privacy and Cookie Policy.';
  ToReadMoreClick: string = 'To read more click';
  here: string = 'here';

  constructor(
    private userservice: UsersService,
    private auth: AuthService,
    private router: Router,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    const userLang = localStorage.getItem('curUserLang');
    if (userLang == 'da') {
      this.redirectGDPRUrl = 'https://igntr.me/gdpr-danish/';

      this.IgnitePrivacyAndCookieConfirmation =
        'Ja tak, jeg accepterer Igntr Privatlivs- og cookiepolitik.';
      this.ToReadMoreClick = 'For at læse mere klik';
      this.here = 'her';
    }
  }
  
  rejectGdpr() {
    this.auth.logout().subscribe(() => {
      this.activeModal.close();
      this.router.navigate(['/signin']);
    });
    return false; // prevent default
  }

  submitGdprInput() {
    this.gdprConfirmed = !this.gdprConfirmed;
  }

  submitGdpr() {
    this.userservice.confirmGdpr().subscribe(() => {
      this.activeModal.close();
    });
  }

  getRedirectGDPRUrl() {
    return '/home';
  }
}

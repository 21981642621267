<div class="box mt-3 m-0 p-0 row" style="width: 100%">
  <div class="box-name-header" style="width: 100%">
    <div class="mt-1 ms-1 row pt-1 m-0 p-0">
      <div class="col-xl-8 col-md-7 col-sm-8 col-9 d-flex ps-2">
        <app-sort-table
          [items]="users"
          [sortProperties]="sortProps"
          (sortedItems)="users = $event"
        />
      </div>

      <div class="col-xl-4 col-md-5 col-sm-4 col-3">
        <span class="txt me-1">{{ "role" | transloco }}</span>
      </div>
    </div>
  </div>

  <br />
  <div *ngFor="let user of users" class="row brd" style="margin-left: 0.1rem">
    <div
      class="col-xl-8 col-md-7 col-sm-8 col-9 pt-3 pb-3 d-flex align-items-center psm0"
    >
      <app-entity-avatar-init
        class="ms-1 me-2"
        [img]="
          blobService.getUserImagePath(user.imagePath, user.hasCompressedImage)
        "
        [name]="user.name"
        [surname]="user.surname"
      ></app-entity-avatar-init>
      <div class="w-100">
        <div class="text-break w-75 hui" [routerLink]="['/users', user.id]">
          {{ user.name }} {{ user.surname }}
        </div>
        <small>
          <span class="txt-email">{{ user.email }}</span>
        </small>
      </div>
    </div>
    <div class="col-sm-2 col-3 d-flex align-items-center">
      <span class="role ms-1" *ngIf="user.roles.length">{{
        user.roles[0].substring(0, 5)
      }}</span>
    </div>
    <div
      *ngIf="(auth.isAnyAdmin$ | async) && innerWidth > 575"
      class="col-2 d-flex align-items-center p-0 justify-content-end"
    >
      <div *ngIf="!user.deletedAt">
        <a class="btn btn-md btn-link w-100" [routerLink]="['/users', user.id]">
          <img src="assets/icons/pencil.svg" alt="Edit" />
        </a>
      </div>
      <div>
        <button
          *ngIf="!user.deletedAt; else restoreUser"
          type="button"
          class="btn btn-md btn-link w-100 text-danger"
          data-bs-target="#deleteusermodal"
          (click)="actionConfirmation = user"
          title="Delete"
          i18n-title="Delete button"
        >
          <img src="assets/icons/trash.svg" alt="Delete" />
        </button>
        <ng-template #restoreUser>
          <button
            type="button"
            class="btn btn-md btn-link w-100 text-danger"
            data-bs-target="#restoreusermodal"
            (click)="actionConfirmation = user; isRestore = true"
            title="Restore"
          >
            <img src="assets/icons/flip-backward.svg" alt="Restore" />
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <br />
  <div class="box-pagination-footer">
    <div
      class="add w-100 d-flex justify-content-end"
      *ngIf="(auth.isAnyAdmin$ | async) && innerWidth < 1050"
    >
      <a routerLink="/users/new" class="btn btn-lg orange-dark text-white">
        <img src="assets/icons/icon-add.svg" alt="Add" />
        <ng-container class="txt-bold-white">{{
          "add" | transloco
        }}</ng-container>
      </a>
    </div>
  </div>
</div>
<app-confirm-delete
  id="deleteusermodal"
  *ngIf="actionConfirmation"
  [deleteCandidate]="actionConfirmation.name"
  [isRestore]="isRestore"
  (confirm)="handleActionClick(actionConfirmation); actionConfirmation = null"
  (cancel)="actionConfirmation = null"
></app-confirm-delete>

<div *ngIf="!isAreaChosen" class="page">
  <div class="background p-0 m-0 bg-image-fs">
    <div class="row p-2">
      <div
        *ngFor="let area of toolboxAreas"
        class="col-md-4 col-xl-3 col-6 d-flex justify-content-center pt-2 mb-3"
      >
        <div
          class="m-1 navcard"
          [class.disabled]="area.disabled"
          (click)="setArea(area.id, area.name)"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
          >
            <div class="mt-3 d-flex justify-content-center align-items-center">
              <img class="menu-img" src="assets/icons/icon-toolbox.svg" />
            </div>
            <span
              class="d-flex justify-content-center align-items-center mt-1 text-white cardtext"
              [class.customShadow]="!area.disabled"
            >
              {{ area.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isAreaChosen" class="row justify-content-center">
  <div class="row col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3 ps-0 pe-0">
    <h2 class="sm-del page-title col-sm-4 col-12">
      {{ "toolBox" | transloco }}
    </h2>

    <div
      class="col-sm-8 col-12 mb-sm-5 mb-3 d-flex justify-content-sm-end justify-content-center"
    >
      <div class="butsect d-flex justify-content-center align-items-center">
        <button
          class="button me-1"
          [class.butActive]="active === 'All'"
          (click)="changeActive('All')"
        >
          {{ "all" | transloco }}
        </button>
        <button
          class="button me-1 d-flex flex-row align-items-center justify-content-center"
          (click)="changeActive('Unread')"
          [class.butActive]="active === 'Unread'"
        >
          {{ "unread" | transloco }}
          <span
            *ngIf="unread && unread >= 1"
            class="ms-1 d-inline d-flex justify-content-center align-items-center unreadnumber"
            >{{ unread }}
          </span>
        </button>
        <button
          class="button me-1"
          [class.butActive]="active === 'Read'"
          (click)="changeActive('Read')"
        >
          {{ "read" | transloco }}
        </button>
      </div>
    </div>
    <div class="content">
      <app-spinner *ngIf="isLoading"></app-spinner>
      <div *ngIf="!isLoading">
        <div>
          <div *ngFor="let toolbox of page" class="mb-sm-4 mb-5 pointer">
            <div
              [routerLink]="['/toolbox-info', toolbox.id]"
              class="toolbox-card row justify-content-end p-0 m-0"
              style="width: 100%"
            >
              <div
                *ngIf="
                  toolbox.documents &&
                  toolbox.documents[0] &&
                  toolbox.documents[0].image
                "
                class="col-sm-auto p-sm-0 col-12 d-flex align-items-center justify-content-center"
                style="min-width: 320px"
              >
                <img
                  class="media-box"
                  src="{{
                    blobService.getCompressedImagePath(
                      toolbox.documents[0].image,
                      320,
                      toolbox.documents[0].isFileOnRightBlob ?? false,
                      toolbox.documents[0].hasCompressedImage320
                    )
                  }} "
                  title="{{ toolbox.documents[0].altText }}"
                />
              </div>
              <div class="col-sm-auto col-12 card-content">
                <div class="ms-sm-3 text-div">
                  <div
                    class="news_title text-break mt-xl-3 mt-lg-2 mt-sm-0 mt-2"
                  >
                    {{ toolbox.title | slice : 0 : 50 }}
                    <span *ngIf="toolbox.title.length > 50">...</span>
                  </div>
                  <p class="news_content text-break mt-2">
                    {{ transform(toolbox.content) }}
                  </p>
                  <div
                    class="d-flex flex-row align-items-center mt-lg-2 mt-xl-4 mt-2"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="rounded-circle me-2 w-100 h-100 wh-40px"
                        src="{{
                          blobService.getUserImagePath(
                            toolbox.userImagePath,
                            toolbox.hasCompression
                          )
                        }}"
                        alt="avatar"
                        style="background: #ff6a00; overflow: hidden"
                      />
                    </div>
                    <div class="d-flex flex-column flex-start">
                      <div class="authorName">
                        {{ toolbox.userName ? toolbox.userName : "Admin" }}
                      </div>
                      <div class="date">
                        {{ toolbox.createdAt | date : "d MMM y" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <hr />
      <app-custom-pagination
        [innerWidth]="innerWidth"
        [page]="(repo.pageNumber$ | async) || 1"
        [total]="(repo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="
          service
            .loadPageCustom(
              active,
              $event,
              undefined,
              undefined,
              ['userGroups', 'documents', 'showPeriod'],
              areaId
            )
            .subscribe()
        "
      ></app-custom-pagination>
    </div>
  </div>
</div>

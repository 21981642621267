import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import {
  IMarketEntity,
  MarketCategory,
  MarketSortOptions,
  MarketplaceRepository,
} from 'app/state/marketplace.repository';

import { MarketCategoriesService } from 'app/state/marketcategories.service';
import { MarketCategoriesRepository } from 'app/state/marketcategories.repository';
import { MarketplaceAllRepository } from 'app/state/marketplaceAll.repository';
import { MarketplaceAllService } from 'app/state/marketpalceAll.service';
import { MarketplaceService } from 'app/state/marketpalce.service';
import {
  IMarketEventLog,
  MarketLogsBuySortOptions,
  MarketplaceLogsBuyRepository,
} from 'app/state/marketpalceLogsBuy.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable } from 'rxjs';
import { MarketplaceLogsBuyService } from 'app/state/marketpalceLogsBuy.service';

@Component({
  selector: 'app-marketplace-logs-sell',
  templateUrl: './marketplace-logs-sell.component.html',
  styleUrls: ['./marketplace-logs-sell.component.scss'],
})
export class MarketplaceLogsSellComponent {
  sortOptions = MarketLogsBuySortOptions;
  innerWidth = 0;
  showAllIds = new Array<string>();
  searchFilter: string = '';
  deleteConfirmation: IMarketEventLog | null = null;
  DEFAULT_MARKET = 'assets/img/market.jpg';
  DEFAULT_IMAGE = 'assets/img/user_default.svg';
  constructor(
    public repo: MarketplaceLogsBuyRepository,
    public service: MarketplaceLogsBuyService,
    public genService: MarketplaceService,
    private categoryService: MarketCategoriesService,
    public categoryRepo: MarketCategoriesRepository
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.refresh();
    this.innerWidth = window.innerWidth;
  }
  refresh() {
    this.service
      .reloadPage(this.searchFilter, undefined, undefined, 'sell')
      .subscribe();
  }
  setFilter(filter?: string) {
    this.searchFilter = filter ?? '';
    this.service
      .searchReloadPage(this.searchFilter, undefined, undefined, 'sell')
      .subscribe();
  }
}

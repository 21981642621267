<app-spinner *ngIf="!(this.isEditorLoaded && this.allAreas)"></app-spinner>
<form
  [ngClass]="{ 'd-none': !(this.isEditorLoaded && this.allAreas) }"
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">
      <div class="inpt-title">{{ "title" | transloco }}</div>
      <input
        id="title"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="{{ 'title' | transloco }}"
        autocomplete="off"
      />
    </div>

    <app-datalist-select
      class=""
      [options]="AreaOptions"
      [multiple]="false"
      [activeValue]="editForm?.value.toolboxAreaId"
      (activeValueChange)="updateValue('toolboxAreaId', $event)"
      label="{{ 'Folder' | transloco }}"
    ></app-datalist-select>

    <div class="mb-3">
      <div class="inpt-title">{{ "description" | transloco }}</div>
      <textarea
        id="editor"
        formControlName="editor"
        type="text"
        class="form-control"
        placeholder="{{ 'description' | transloco }}"
        autocomplete="off"
      >
      </textarea>
    </div>
    <div>
      <div class="inpt-title">{{ "file" | transloco }}</div>
      <app-image-video-component
        [entity]="fileDocument"
      ></app-image-video-component>
    </div>

    <div class="inpt-title">{{ "attachLinks" | transloco }}</div>

    <app-attach-links [docList]="editedToolBox?.attachedLinks ?? []">
    </app-attach-links>
    <div class="inpt-title">{{ "attachDocuments" | transloco }}</div>

    <app-attach-documents [docList]="initialDocs"> </app-attach-documents>

    <div class="inpt-title">{{ "showPeriod" | transloco }}</div>

    <app-period-select [defaultValues]="timestamp"></app-period-select>
    <div class="form-group form-floating mb-3">
      <app-datalist-select
        [activeValue]="editForm?.value?.userGroupIds"
        [options]="groupsOptions"
        [optionsDeleted]="groupsOptionsDeleted"
        (activeValueChange)="updateValue('userGroupIds', $event)"
        [multiple]="true"
        label="{{ 'userGroups' | transloco }}"
      ></app-datalist-select>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm">
      {{ "save" | transloco }}
    </button>
  </div>
</form>

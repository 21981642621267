<div class="row justify-content-center">
  <h2 class="sm-del page-title col-sm-8 col-12">
    {{ "marketplaceAdmin" | transloco }}
  </h2>
  <!--<div
    class="col-sm-6 col-8 mb-sm-5 d-flex justify-content-sm-end justify-content-center"
  >
    <div class="butsect d-flex justify-content-center align-items-center">
      <a class="btn button butActive me-1">{{ "bought" | transloco }}</a>
      <a href="marketplace/logs/sell" class="btn button">{{
        "sold" | transloco
      }}</a>
    </div>
  </div>-->

  <div class="row col-12 col-lg-11 col-xl-10 pb-3 pt-sm-3 max-900 ps-0 pe-0">
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="
          service.sort($event, searchFilter, undefined, 'buy').subscribe()
        "
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-6 col-6 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">{{ "entity" | transloco }}</span>
          </div>
          <div class="col-sm-6 col-6 d-flex p-0">
            <span class="txt mt-1 ms-2">{{ "createdAt" | transloco }}</span>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let item of repo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-6 col-6 pt-3 pb-3 d-flex align-items-center psm0">
          <input
            class="check form-check-input me-2"
            type="checkbox"
            style="margin-left: 0.1rem"
          />
          <div class="txt-name oneLineTextHeader">
            <div>
              <!-- <span *ngIf="item.user.name || item.user.surname"
                >{{ item.user.name + " " + item.user.surname | slice : 0 : 50 }}
              </span>

              <span *ngIf="item.user.email"
                >{{ item.user.email | slice : 0 : 50 }}
              </span> -->
              {{ item.marketEvent.title | slice : 0 : 50 }}
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-6 pt-3 pb-3 d-flex align-items-center psm0">
          <div class="txt-name oneLineTextHeader">
            <div>
              {{ item.createdAtString | slice : 0 : 50 }}
            </div>
          </div>
        </div>
      </div>
      <br />

      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            repo.setPage($event);
            service
              .reloadPage(searchFilter, undefined, undefined, 'buy')
              .subscribe()
          "
        ></app-custom-pagination>
      </div>
    </div>
  </div>
</div>

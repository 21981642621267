import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthRepository } from 'app/state/auth.repository';
import { RouteReuseStrategy } from '@angular/router';
import { PostsService } from 'app/state/posts.service';
import {
  ToolBox,
  ToolBoxesRepository,
  toolboxSortOptions,
} from 'app/state/toolbox.repository';
import { IToolboxArea } from 'app/state/toolboxarea.repository';
import { ToolboxAreaService } from 'app/state/toolboxarea.service';
import { Router } from '@angular/router';
import { BackNavigationService } from 'app/helpers/services/backButtonNavigationService';
import { BlobCompressService } from 'app/shared/azure-blob/blob-compress.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { ToolBoxesService } from 'app/api/services/toolbox.service';

@Component({
  selector: 'app-toolboxes',
  templateUrl: './toolboxes.component.html',
  styleUrls: ['./toolboxes.component.scss'],
})
export class ToolboxesComponent implements OnInit {
  DEFAULT_POST_IMAGE = 'assets/img/preview.jpg';
  sortOptions = toolboxSortOptions;
  exportFactory = () => this.service.export();
  importFactory = (file: File) => this.service.import(file);
  innerWidth = 0;
  active: string = 'All';
  unread: number | null = null;
  areaId: string | undefined;
  pageCount: number | undefined;

  page: ToolBox[] | undefined;
  isLoading: boolean = true;

  constructor(
    public navigationService: BackNavigationService,
    public repo: ToolBoxesRepository,
    public service: ToolBoxesService,
    public auth: AuthRepository,
    private toolboxAreaService: ToolboxAreaService,
    public blobService: BlobStorageService
  ) {
    repo.setPage(1);
    repo.paginationData$.subscribe((x) => {
      if (this.pageCount) {
        if (x.total === 0 && this.pageCount > 0) {
          this.isLoading = false;
        }
      }
      this.pageCount = x.total;
    });

    repo.page$.subscribe((x) => {
      this.isLoading = false;
      this.page = x.map((x) => ({
        id: x.id,
        title: x.title,
        content: x.content,
        comments: x.comments,
        createdAt: x.createdAt,
        createdById: x.createdById,
        updatedById: x.updatedById,
        userId: x.userId,
        video: x.video,
        previewImage: x.previewImage,
        userImagePath: x.userImagePath,
        hasCompression: x.hasCompression,
        userName: x.userName,
        isRead: x.isRead,
        userGroupIds: x.userGroupIds,
        documentIds: x.documentIds,
        showFrom: x.showFrom,
        showTo: x.showTo,
        documents: x.documents?.filter((y) =>
          ['image', 'videoUrl', 'video'].includes(y.type ?? '')
        ),
      }));
    });
  }

  news: ToolBox[] = [];
  toolboxAreas: IToolboxArea[] | null = null;
  isAreaChosen = false;
  areaName: string | undefined = undefined;

  setToolBoxMenu() {
    this.toolboxAreaService.loadMenuItems().subscribe((toolboxAreas) => {
      if (toolboxAreas && toolboxAreas?.length > 0) {
        this.toolboxAreas = toolboxAreas;
      } else {
        this.isAreaChosen = true;
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.setToolBoxMenu();
    this.innerWidth = window.innerWidth;
    this.service.getCount().subscribe((x) => {
      this.unread = x;
    });
  }

  loadData() {
    this.service
      .reloadPageCustom(
        this.active,
        undefined,
        undefined,
        ['userGroups', 'showPeriod', 'documents'],
        this.areaId
      )
      .subscribe((x) => {
        this.isAreaChosen = true;
      });
  }

  setArea(areaId?: string, areaName?: string) {
    this.areaId = areaId;
    this.areaName = areaName;
    this.loadData();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  changeActive(option: string) {
    this.active = option;
    this.service
      .reloadPageCustom(
        this.active,
        undefined,
        undefined,
        ['userGroups', 'documents', 'showPeriod'],
        this.areaId
      )
      .subscribe();
    this.isLoading = true;
  }

  transform(value?: string): any {
    var string = value
      ? value.replace(/<.*?>/g, '').replace(/&nbsp;/g, '')
      : '';
    return string.length > 80 ? string.slice(0, 80) + '...' : string;
  }
}

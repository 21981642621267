import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { UserRoles } from '../state/auth.repository';
import { TenantFeatures } from '../state/feature.repository';
import { AdminMarketplaceComponent } from '../modules/marketplace/pages/admin-marketplace/admin-marketplace.component';
import { MarketplaceCreateComponent } from '../modules/marketplace/pages/marketplace-create/marketplace-create.component';
import { MarketplacePublicPageComponent } from '../modules/marketplace/pages/marketplace-public-page/marketplace-public-page.component';
import { MarketplacePrivatePageComponent } from '../modules/marketplace/pages/marketplace-private-page/marketplace-private-page.component';
import { MarketplaceInfoComponent } from '../modules/marketplace/pages/marketplace-info/marketplace-info.component';
import { MarketplaceLogsBuyComponent } from 'app/modules/marketplace/pages/marketplace-logs-buy/marketplace-logs-buy.component';
import { MarketplaceLogsSellComponent } from 'app/modules/marketplace/pages/marketplace-logs-sell/marketplace-logs-sell.component';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'admin/marketplace',
        component: AdminMarketplaceComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'marketplace/public',
        component: MarketplacePublicPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Marketplace,
        },
      },
      {
        path: 'marketplace/private',
        component: MarketplacePrivatePageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Marketplace,
        },
      },
      {
        path: 'marketplace/info/:id',
        component: MarketplaceInfoComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Marketplace,
        },
      },
      {
        path: 'admin/marketplace/:type/:id',
        component: MarketplaceCreateComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superadmin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Marketplace,
        },
      },
      {
        path: 'admin/marketplace/:type',
        component: MarketplaceCreateComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.Superadmin, UserRoles.TenantAdmin],
          feature: TenantFeatures.Marketplace,
        },
      },
      {
        path: 'marketplace/logs/buy',
        component: MarketplaceLogsBuyComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Marketplace,
        },
      },
      {
        path: 'marketplace/logs/sell',
        component: MarketplaceLogsSellComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Marketplace,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MarketplaceRoutingModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, switchMap, tap } from 'rxjs';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { IMarketEntity, MarketplaceRepository } from './marketplace.repository';
import { MarketplaceAllRepository } from './marketplaceAll.repository';
import { MarketplacePrivateRepository } from './marketplacePrivate.repository';

const API = '/api/marketplace/getAllPrivate/WithoutTenant';

@Injectable({ providedIn: 'root' })
export class MarketplacePrivateService extends BaseService<IMarketEntity> {
  constructor(http: HttpClient, repo: MarketplacePrivateRepository) {
    super(API, http, repo);
  }
  delete(id: string): Observable<void> {
    return this.http.delete<void>(`api/marketplace/WithoutTenant/${id}`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(),
      this.repo.trackOne(id)
    );
  }
}

export const CLIENT_PATHS = {
  reports: 'reports',
  personalPlan: 'personalplan',
  personalCoach: 'personalcoach',
  deleting: 'deleting',
  emails: 'notifications',
  usergroups: 'usergroups',
  mwEvents: 'mwevents',
  calendar: 'calendar',
  feed: 'feed',
  onboarding: 'onboarding',
};
export const SUB_PATHS = {};

import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

import {
  IMarketEntity,
  MarketCategory,
  MarketSortOptions,
  MarketplaceRepository,
} from 'app/state/marketplace.repository';

import { MarketCategoriesService } from 'app/state/marketcategories.service';
import { MarketCategoriesRepository } from 'app/state/marketcategories.repository';
import { MarketplaceAllRepository } from 'app/state/marketplaceAll.repository';
import { MarketplaceAllService } from 'app/state/marketpalceAll.service';
import { MarketplaceService } from 'app/state/marketpalce.service';

@Component({
  selector: 'app-marketplace-public-page',
  templateUrl: './marketplace-public-page.component.html',
  styleUrls: ['./marketplace-public-page.component.scss'],
})
export class MarketplacePublicPageComponent {
  sortOptions = MarketSortOptions;
  innerWidth = 0;
  showAllIds = new Array<string>();
  searchFilter: string = '';
  deleteConfirmation: IMarketEntity | null = null;
  DEFAULT_MARKET = 'assets/img/market.jpg';
  DEFAULT_IMAGE = 'assets/img/user_default.svg';
  constructor(
    public repo: MarketplaceAllRepository,
    public service: MarketplaceAllService,
    public genService: MarketplaceService,
    private categoryService: MarketCategoriesService,
    public categoryRepo: MarketCategoriesRepository
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.service.load().subscribe();
    this.categoryService.load().subscribe();
    this.innerWidth = window.innerWidth;
  }

  getQuantity(markets: IMarketEntity[], category: MarketCategory) {
    return markets.filter((x) => x.marketCategoryId === category.id).length;
  }

  getMarkets(markets: IMarketEntity[], category: MarketCategory) {
    return markets.filter((x) => x.marketCategoryId === category.id);
  }

  isInShow(id: string) {
    return this.showAllIds.find((x) => x === id);
  }

  remove(id: string) {
    this.showAllIds = this.showAllIds.filter((item) => item !== id);
  }

  // scrollDown(): void {
  //   setTimeout(() => window.scrollTo({ top: window.pageYOffset + 500, behavior: 'smooth' }), 500);
  // }

  buyMarket(entity: IMarketEntity) {
    this.genService
      .buy({
        id: entity.id,
        title: entity.title,
        tenantId: entity.tenantId,
        purchaseType: entity.purchaseType,
      })
      .subscribe();
  }
  refreshDataProject() {
    this.service.reloadPage(this.searchFilter).subscribe();
    /*  this.repo.all$;*/
  }
  handleDeleteClick(post: IMarketEntity) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshDataProject();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}

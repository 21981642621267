import { Component, HostListener } from '@angular/core';
import { AuthRepository } from 'app/state/auth.repository';

import {
  ToolBox,
  ToolBoxesRepository,
  toolboxSortOptions,
} from 'app/state/toolbox.repository';
import { IConfirm } from 'app/state/tenants.repository';
import { SortProperty } from 'app/interfaces/sort-property';
import { ToolBoxesService } from 'app/api/services/toolbox.service';
import { ToolboxForListDto } from 'app/api/models/toolbox/toolboxForListDto';

@Component({
  selector: 'app-toolboxes-admin',
  templateUrl: './toolboxes-admin.component.html',
  styleUrls: ['./toolboxes-admin.component.scss'],
})
export class ToolboxesAdminComponent {
  innerWidth = 0;
  isLoading: boolean = true;
  tenantId: string | null = null;
  searchForAdmin: boolean = true;
  deleteConfirmation: ToolboxForListDto | null = null;
  cloneConfirmation: ToolboxForListDto | null = null;
  toolboxes: ToolboxForListDto[] = [];
  filteredToolboxes: ToolboxForListDto[] | null = null;

  sortProps: SortProperty[] = [
    {
      target: 'title',
      classes: ['col-6', 'col-sm-4', 'col-lg-2', 'mb-0', 'p-0', 'd-flex'],
      isAscending: true,
    },
    {
      target: 'userGroups',
      classes: ['col-3', 'show-on-lg'],
      disableSort: true,
    },
    {
      target: 'folderName',
      label: 'Folder',
      classes: ['col-5', 'col-lg-2', 'ps-3', 'show-on-lg'],
    },
    {
      target: 'createdAt',
      label: 'date',
      classes: ['col-6', 'col-sm-4', 'col-lg-2', 'ps-3', 'd-flex'],
    },
  ];

  constructor(
    public repo: ToolBoxesRepository,
    public service: ToolBoxesService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {
    this.refreshData();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service.loadAll().subscribe((x) => {
      this.toolboxes = x;
      this.filteredToolboxes = x;
      this.isLoading = false;
    });
  }

  handleDeleteClick(toolBox: ToolboxForListDto) {
    this.service.deleteWithQueryId(toolBox.id).subscribe((x) => {
      this.refreshData();
    });
  }

  handleCloneClick(event: IConfirm) {
    const activeTenantId = localStorage.getItem('activeTenantId');
    this.service.clone(event, activeTenantId!).subscribe((x) => {
      this.refreshData();
    });
  }

  getGroupFieldValue(groups: string[]) {
    if (groups.length > 1) {
      return groups[0] + '...';
    }
    return groups[0];
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

import { DocumentsRepository, IDocument } from './document.repository';

const API = '/api/documents';

@Injectable({ providedIn: 'root' })
export class DocumentsService extends BaseService<IDocument> {
  constructor(http: HttpClient, repo: DocumentsRepository) {
    super(API, http, repo);
  }

  getAllFrom(id: string) {
    return this.http.get<IDocument[]>(`${API}/loadallfrom/${id}`);
  }

  updateDocument(entity: Partial<IDocument>) {
    return this.http.patch<IDocument>(API + '/updatedocument', entity);
  }

  add(post: Partial<IDocument>) {
    const formData = new FormData();
    formData.append('file', post.document!);
    return this.http.post<IDocument>(`${API}/${post.id}/file`, formData);
    // return new Observable<Document>();
  }

  patch(post: Partial<IDocument>) {
    const formData = new FormData();
    formData.append('file', post.document!);
    return this.http.patch<IDocument>(`${API}/${post.id}/file`, formData);
  }
  loadOneDocument(id: string) {
    return this.http.get<IDocument>(API + '/loadOneDocument/' + id);
  }

  postDocument(entity: Partial<IDocument>) {
    return this.http.post<IDocument>(API + '/postdocument', entity);
  }
  //loadPageCustom(page: number, take: number = DEFAULT_ENTITIES_PER_PAGE): Observable<PaginationData & { data: IDocument[] }> {
  //  const sortOrder = this.repo.getSort();
  //  const query = [
  //    `page=${page}`,
  //    `take=${take}`,
  //    `sort=${sortOrder.parameter.property}`,
  //    `direction=${sortOrder.direction}`
  //  ];
  //  this.repo.setPage(page);
  //  return this.http
  //    .get<PaginationData & { data: IDocument[] }>(`/api/documents?${query.join('&')}`)
  //    .pipe(
  //      tap(res => this.repo.addPage(res))
  //    );
  //}

  //reloadPageCustom(defaultTake: number = DEFAULT_ENTITIES_PER_PAGE): Observable<PaginationData & { data: IDocument[] } | null> {
  //  const data = this.repo.getPaginationData();
  //  if (data && Object.keys(data.pages).length) {
  //    this.repo.clearPages();
  //    return this.loadPageCustom(data.currentPage, data.perPage);
  //  }
  //  return this.loadPageCustom(1, defaultTake);
  //}
}

import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface IToolboxArea {
  id: string;
  createdAt: string;
  name: string;
  isActive: boolean;
  disabled?: boolean;
  userGroupIds?: string[];
}

export const ToolboxAreaSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label CreatedAt:CreatedAt`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class ToolboxAreaRepository extends BaseRepository<IToolboxArea> {
  constructor() {
    super('ToolboxAreaOptions', ToolboxAreaSortOptions);
  }
}

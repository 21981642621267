<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "toolBox" | transloco }}
      </h2>
    </div>
    <div class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end">
      <div class="d-flex">
        <a
          routerLink="/admin/toolbox/new"
          class="btn btn-lg orange-dark text-white"
        >
          <img src="assets/img/plus_add.svg" />
          <span class="text-btn-add">{{ "add" | transloco }}</span>
        </a>
      </div>
    </div>

    <div class="btn-group btn-group-lg mb-4 p-0" role="group">
      <button disabled class="btn btn-orange" routerLink="/personalplan">
        {{ "tools" | transloco }}
      </button>
      <button class="btn btn-orange" routerLink="/admin/toolbox-areas">
        {{ "Folder" | transloco }}
      </button>
    </div>

    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search-input
        class="col-auto"
        style="min-width: 50%"
        [searchProperties]="['title']"
        [items]="toolboxes"
        (foundItems)="filteredToolboxes = $event"
      />
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <app-sort-table
            [items]="filteredToolboxes"
            [sortProperties]="sortProps"
            (sortedItems)="filteredToolboxes = $event"
          />
        </div>
      </div>

      <br />
      <div
        *ngFor="let toolBox of filteredToolboxes"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div
          class="col-6 col-sm-4 col-lg-2 pt-3 pb-3 d-flex align-items-center psm0"
        >
          <div class="txt-name">
            <div
              [routerLink]="['/admin/toolbox/', toolBox.id]"
              class="text-break splice-text"
            >
              {{ toolBox.title }}
            </div>
          </div>
        </div>
        <div
          class="col-3 d-flex align-items-center show-on-lg"
          popover="{{ toolBox.userGroupNames }}"
          triggers="hover"
          placement="top"
          style="cursor: pointer"
        >
          {{ getGroupFieldValue(toolBox.userGroupNames) }}
        </div>
        <div class="col-2 d-flex align-items-center show-on-lg">
          {{ toolBox.folderName }}
        </div>
        <div class="col-4 col-lg-2 pt-3 pb-3 d-flex align-items-center">
          <div class="txt-name">
            <div class="role text-break splice-text">
              {{ toolBox.createdAt | date : "dd-MM-YYYY HH:mm" }}
            </div>
          </div>
        </div>
        <div
          *ngIf="innerWidth > 575"
          class="col-6 col-sm-4 col-lg-3 d-flex align-items-center p-0 justify-content-end"
        >
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/admin/toolbox/', toolBox.id]"
            >
              <img src="assets/img/edit.svg" />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#cloneusermodal"
              (click)="cloneConfirmation = toolBox"
              title="{{ 'clone' | transloco }}"
            >
              <img src="assets/img/clone.svg" />
            </button>
          </div>
          <div>
            <a
              class="btn btn-md btn-link w-100"
              title="{{ 'notification' | transloco }}"
              [routerLink]="['/admin/toolbox/notification/new', toolBox.id]"
            >
              <img src="assets/icons/send.svg" />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = toolBox"
              title="{{ 'delete' | transloco }}"
              i18n-title="Delete button"
            >
              <img src="assets/img/delete.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
    <app-clone-pop-up
      id="cloneusermodal"
      *ngIf="cloneConfirmation"
      [cloneCandidateId]="cloneConfirmation.id"
      [cloneCandidateName]="cloneConfirmation.title"
      [currentTenant]="tenantId"
      (confirm)="
        handleCloneClick($event); cloneConfirmation = null; (refreshData)
      "
      (cancel)="cloneConfirmation = null"
    ></app-clone-pop-up>
  </div>
</div>

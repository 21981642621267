import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthRepository } from '../../../../state/auth.repository';

import {
  IMarketEntity,
  MarketCategory,
  MarketplaceRepository,
} from '../../../../state/marketplace.repository';
import { IMWEvent } from '../../../../state/mwevent.repository';
import { MWEventService } from '../../../../state/mwevent.service';
import { MarketplaceService } from 'app/state/marketpalce.service';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-marketplace-form',
  templateUrl: './marketplace-form.component.html',
  styleUrls: ['./marketplace-form.component.scss'],
})
export class MarketplaceFormComponent {
  Events: IMWEvent[] = [];
  marketForm: UntypedFormBuilder | any;
  file: File | null = null;
  errors: string[] | null = null;
  eventsOptions: DatalistOption[] | null = null;
  categoriesOptions: DatalistOption[] | null = null;

  @Input() editedMarketEntity?: IMarketEntity | null;

  @Output() marketEntitySubmit = new EventEmitter<Partial<IMarketEntity>>();

  @Input() set events(value: IMWEvent[] | null) {
    if (!value) {
      this.eventsOptions = null;
    } else {
      this.eventsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }

  @Input() set categories(value: MarketCategory[] | null) {
    if (!value) {
      this.categoriesOptions = null;
    } else {
      this.categoriesOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.marketForm) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        if (
          this.file?.type == 'image/jpeg' ||
          this.file?.type == 'image/png' ||
          this.file?.type == 'image/svg+xml'
        ) {
          this.marketForm.value.file = this.file;
        } else {
          this.marketForm.value.file = null;
          this.file = null;
        }
      }
    }
  }
  constructor(
    private formBuilder: UntypedFormBuilder,
    private repo: MarketplaceRepository,
    private mwEventService: MWEventService,
    private service: MarketplaceService,
    public authRepo: AuthRepository
  ) {}

  updateValue(control: string, value: any) {
    const controlObject = this.marketForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  ngOnInit() {
    this.mwEventService.getForOptions().subscribe((x) => {
      this.events = x;
    });
    this.marketForm = this.formBuilder.group({
      title: [this.editedMarketEntity?.title, Validators.required],
      id: this.editedMarketEntity?.id,
      description: this.editedMarketEntity?.description,
      mwEventId: this.editedMarketEntity?.mwEventId,
      file: [this.editedMarketEntity?.file],
      marketCategoryId: [this.editedMarketEntity?.marketCategoryId],
    });
  }

  onSubmit() {
    if (this.marketForm && !this.marketForm.valid) {
      this.marketForm.markAllAsTouched();
      return;
    }
    const title = this.marketForm.value?.title?.toString();
    const id = this.marketForm.value?.id;
    const description = this.marketForm.value?.description;
    const mwEventId = this.marketForm.value?.mwEventId;
    const marketCategoryId = this.marketForm.value?.marketCategoryId;
    const file = this.marketForm.value?.file;
    if (!this.file || this.file?.type.includes('image')) {
      this.marketEntitySubmit.emit({
        id,
        title,
        description,
        mwEventId,
        file,
        marketCategoryId,
      });
    } else {
      this.errors = ['file type is not valid'];
    }
  }
}

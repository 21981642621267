import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { UserRoles } from '../state/auth.repository';
import { TenantFeatures } from '../state/feature.repository';
import { ToolboxesComponent } from 'app/modules/toolbox/pages/toolboxes/toolboxes.component';

import { ToolboxesAdminComponent } from 'app/modules/toolbox/pages/toolboxes-admin/toolboxes-admin.component';
import { ToolboxesEditComponent } from 'app/modules/toolbox/pages/toolboxes-edit/toolboxes-edit.component';
import { ToolboxesInfoComponent } from 'app/modules/toolbox/pages/toolboxes-info/toolboxes-info.component';
import { ToolboxAreasComponent } from 'app/modules/toolbox/pages/toolbox-areas/toolbox-areas.component';
import { ToolboxAreaEditComponent } from 'app/modules/toolbox/pages/toolbox-area-edit/toolbox-area-edit.component';
import { ToolboxAreaAddComponent } from 'app/modules/toolbox/pages/toolbox-area-add/toolbox-area-add.component';
import { ToolboxNotificationCreateComponent } from 'app/modules/toolbox/pages/toolbox-notification/toolbox-notification.component';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'toolbox',
        component: ToolboxesComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'toolbox/:area',
        component: ToolboxesComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'admin/toolbox',
        component: ToolboxesAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'admin/toolbox/:id',
        component: ToolboxesEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.ToolBox,
        },
      },

      {
        path: 'toolbox-info/:id',
        component: ToolboxesInfoComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'admin/toolbox-areas',
        component: ToolboxAreasComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'admin/toolbox-areas/new',
        component: ToolboxAreaAddComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'admin/toolbox-areas/edit/:id',
        component: ToolboxAreaEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: 'admin/toolbox/notification/new/:id',
        component: ToolboxNotificationCreateComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ToolBoxRoutingModule {}

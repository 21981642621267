import { Component, HostListener } from '@angular/core';
import { MarketplaceService } from '../../../../state/marketpalce.service';
import { MarketplacePrivateService } from '../../../../state/marketpalcePrivate.service';
import {
  IMarketEntity,
  MarketplaceRepository,
  MarketSortOptions,
} from '../../../../state/marketplace.repository';
import { MarketplacePrivateRepository } from '../../../../state/marketplacePrivate.repository';
import { IMWEvent } from '../../../../state/mwevent.repository';
import { IConfirm } from 'app/state/tenants.repository';

@Component({
  selector: 'app-admin-marketplace',
  templateUrl: './admin-marketplace.component.html',
  styleUrls: ['./admin-marketplace.component.scss'],
})
export class AdminMarketplaceComponent {
  sortOptions = MarketSortOptions;
  cloneConfirmation: IMarketEntity | null = null;
  innerWidth = 0;
  searchFilter: string = '';
  deleteConfirmation: IMarketEntity | null = null;
  tenantId: string | null = null;
  constructor(
    public repo: MarketplacePrivateRepository,
    public service: MarketplacePrivateService,
    public genService: MarketplaceService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe((x) => {});
    this.tenantId = localStorage.getItem('activeTenantId');
    this.innerWidth = window.innerWidth;
  }
  refreshDataProject() {
    this.service.reloadPage(this.searchFilter).subscribe();
    /*  this.repo.all$;*/
  }

  handleCloneClick(event: IConfirm) {
    this.genService
      .clone({
        id: event.id,
        title: event.title,
        tenantId: event.tenantId,
        purchaseType: event.purchaseType,
      })
      .subscribe((x) => {
        this.refreshDataProject();
      });
  }

  handleDeleteClick(post: IMarketEntity) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshDataProject();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}

<div class="row justify-content-center">
  <h2 class="sm-del page-title col-sm-4 col-12">
    {{ "collaborations" | transloco }}
  </h2>
  <div
    class="col-sm-6 col-8 mb-sm-5 mb-3 d-flex justify-content-sm-end justify-content-center"
  >
    <div class="butsect d-flex justify-content-center align-items-center">
      <a class="btn button butActive me-1">
        {{ "collaborations" | transloco }}
      </a>
      <a href="marketplace/logs/sell" class="btn button">{{
        "sold" | transloco
      }}</a>
    </div>
  </div>
  <div class="row col-12 col-lg-11 col-xl-10 pb-3 pt-sm-3 max-900 ps-0 pe-0">
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="service.sort($event, searchFilter).subscribe()"
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-5 col-5 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">{{ "name" | transloco }}</span>
          </div>
          <div class="col-sm-6 col-6 d-flex p-0">
            <span class="txt mt-1 ms-2">{{ "tenant" | transloco }}</span>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let item of repo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-5 col-5 pt-3 pb-3 d-flex align-items-center psm0">
          <input
            class="check form-check-input me-2"
            type="checkbox"
            style="margin-left: 0.1rem"
          />
          <div class="txt-name oneLineTextHeader">
            <div [routerLink]="['/admin/marketplace/edit', item.id]">
              {{ item.title | slice : 0 : 50 }}
            </div>
          </div>
        </div>
        <div class="col-sm-5 col-5 pt-3 pb-3 d-flex align-items-center psm0">
          <div class="txt-name oneLineTextHeader">
            <div [routerLink]="['/admin/marketplace/edit', item.id]">
              {{ item.tenantName | slice : 0 : 50 }}
            </div>
          </div>
        </div>

        <div
          *ngIf="innerWidth > 575"
          class="col-2 d-flex align-items-center p-0 justify-content-end"
        >
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/admin/marketplace/edit', item.id]"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
                  stroke="#475467"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = item"
              title="{{ 'delete' | transloco }}"
              i18n-title="Delete button"
            >
              <img src="assets/img/delete.svg" />
            </button>
          </div>
        </div>
      </div>
      <br />

      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service.loadFilterPage(searchFilter, $event).subscribe()
          "
        ></app-custom-pagination>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
  </div>
</div>

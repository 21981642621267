import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { MarketCategory } from './marketplace.repository';
import { MarketCategoriesRepository } from './marketcategories.repository';

const API = '/api/marketcategories';

@Injectable({ providedIn: 'root' })
export class MarketCategoriesService extends BaseService<MarketCategory> {
  constructor(http: HttpClient, repo: MarketCategoriesRepository) {
    super(API, http, repo);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinPipe } from './pipes/join.pipe';
import { NgVarDirective } from './directives/ng-var.directive';
import { SortPipe } from './pipes/sort.pipe';
import { FindByPipe } from './pipes/find-by.pipe';
import { PropPipe } from './pipes/prop.pipe';
import { TimingPipe } from './pipes/timing.pipe';
import { ValidateStateDirective } from './directives/validate-state.directive';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { SimpleCardComponent } from './components/simple-card/simple-card.component';
import { DatalistSelectComponent } from './components/datalist-select/datalist-select.component';
import { RolePinComponent } from './components/role-pin/role-pin.component';
import { EntityAvatarComponent } from './components/entity-avatar/entity-avatar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SortDropdownComponent } from './components/sort-dropdown/sort-dropdown.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarPipe } from './pipes/calendar.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { GoogleMapsLinkComponent } from './components/google-maps-link/google-maps-link.component';
import { StatCardComponent } from './components/stat-card/stat-card.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { TranslateRolesPipe } from './pipes/translate-roles.pipe';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SortTableComponent } from './components/sort-table/sort-table.component';
import { EntityAvatarInitComponent } from './components/entity-avatar-init/entity-avatar-init.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { SearchComponent } from './components/search/search.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ImportUsersModalComponent } from './components/import-users-modal/import-users-modal.component';
import { GdprConfirmationComponent } from './components/gdpr-confirmation/gdpr-confirmation.component';
import { MultipleDatalistModalComponent } from './components/multiple-datalist-modal/multiple-datalist-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ClonePopUpComponent } from './components/clone-pop-up/clone-pop-up.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { FilterComponent } from './components/filter/filter.component';
import { DeleteInputConfirmationComponent } from './components/deleteConfirmationWithInput/delete-input-confirmation.component';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { ManualNotificationComponent } from './components/manualNotifacationCreateForm/manual-notification.component';
import { VideoPageComponent } from './components/mwevent-pages/video-page/video-page.component';
import { TextPageComponent } from './components/mwevent-pages/text-page/text-page.component';
import { AttachmentsComponent } from './components/mwevent-pages/attachments/attachments.component';
import { IframePageComponent } from './components/mwevent-pages/iframe-page/iframe-page.component';
import { ImagePageComponent } from './components/mwevent-pages/image-page/image-page.component';
import { MweventLivepreviewComponent } from './components/mwevent-pages/mwevent-livepreview/mwevent-livepreview.component';
import { QuestionsPageComponent } from './components/mwevent-pages/questions-page/questions-page.component';
import { TitlePageComponent } from './components/mwevent-pages/title-page/title-page.component';
import { SanitizeUrl } from '../mwevents/pages/mwevent-user/mwevent-user.component';
import { NavPaneComponent } from './components/mwevent-pages/nav-pane/nav-pane.component';
import { AddSubeventPopUpComponent } from './components/mwevent-pages/add-subevent-pop-up/add-subevent-pop-up.component';
import { LivePreviewComponent } from './components/mw-modules/live-preview/live-preview.component';
import { IframeModuleComponent } from './components/mw-modules/iframe-module/iframe-module.component';
import { ImageModuleComponent } from './components/mw-modules/image-module/image-module.component';
import { TextModuleComponent } from './components/mw-modules/text-module/text-module.component';
import { MainPageComponent } from './components/mw-modules/main-page/main-page.component';
import { VideoModuleComponent } from './components/mw-modules/video-module/video-module.component';

@NgModule({
  declarations: [
    JoinPipe,
    NgVarDirective,
    SortPipe,
    FindByPipe,
    PropPipe,
    TimingPipe,
    ValidateStateDirective,
    StripHtmlPipe,
    SimpleCardComponent,
    DatalistSelectComponent,
    RolePinComponent,
    EntityAvatarComponent,
    SpinnerComponent,
    SortDropdownComponent,
    ErrorAlertComponent,
    CalendarPipe,
    PaginationComponent,
    ScrollSpyDirective,
    GoogleMapsLinkComponent,
    StatCardComponent,
    ImportExportComponent,
    TranslateRolesPipe,
    ImageUploaderComponent,
    ImageSliderComponent,
    ImageCardComponent,
    ConfirmDeleteComponent,
    SortTableComponent,
    EntityAvatarInitComponent,
    CustomPaginationComponent,
    SearchComponent,
    ImportUsersModalComponent,
    GdprConfirmationComponent,
    MultipleDatalistModalComponent,
    ClonePopUpComponent,
    SearchInputComponent,
    FilterComponent,
    DeleteInputConfirmationComponent,
    SanitizeHtmlPipe,
    SanitizeUrl,
    ManualNotificationComponent,

    VideoPageComponent,
    TextPageComponent,
    AttachmentsComponent,
    IframePageComponent,
    ImagePageComponent,
    MweventLivepreviewComponent,
    QuestionsPageComponent,
    TitlePageComponent,
    NavPaneComponent,
    AddSubeventPopUpComponent,
    LivePreviewComponent,
    IframeModuleComponent,
    ImageModuleComponent,
    TextModuleComponent,
    MainPageComponent,
    VideoModuleComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslocoRootModule,
    ModalModule.forRoot(),
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatListModule,
    MatDialogModule,
    PopoverModule,
    TranslocoRootModule,
  ],
  exports: [
    SanitizeHtmlPipe,
    JoinPipe,
    NgVarDirective,
    SortPipe,
    FindByPipe,
    PropPipe,
    TimingPipe,
    ValidateStateDirective,
    StripHtmlPipe,
    SimpleCardComponent,
    DatalistSelectComponent,
    RolePinComponent,
    EntityAvatarComponent,
    SpinnerComponent,
    SortDropdownComponent,
    ErrorAlertComponent,
    CalendarPipe,
    PaginationComponent,
    ScrollSpyDirective,
    GoogleMapsLinkComponent,
    StatCardComponent,
    ImportExportComponent,
    ImageUploaderComponent,
    TranslateRolesPipe,
    ImageSliderComponent,
    ImageCardComponent,
    ConfirmDeleteComponent,
    EntityAvatarInitComponent,
    CustomPaginationComponent,
    SearchComponent,
    ImportUsersModalComponent,
    GdprConfirmationComponent,
    MultipleDatalistModalComponent,
    PopoverModule,
    ClonePopUpComponent,
    SortTableComponent,
    SearchInputComponent,
    FilterComponent,
    DeleteInputConfirmationComponent,
    ManualNotificationComponent,

    VideoPageComponent,
    TextPageComponent,
    AttachmentsComponent,
    IframePageComponent,
    ImagePageComponent,
    MweventLivepreviewComponent,
    QuestionsPageComponent,
    TitlePageComponent,
    NavPaneComponent,
    SanitizeUrl,
    AddSubeventPopUpComponent,

    LivePreviewComponent,
    IframeModuleComponent,
    ImageModuleComponent,
    TextModuleComponent,
    MainPageComponent,
    VideoModuleComponent,
  ],
})
export class SharedModule {}

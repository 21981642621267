import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';
import { DataService, FileSetttings } from 'app/shared/data.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Observable, combineAll, from, map } from 'rxjs';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';

@Component({
  selector: 'app-attach-documents',
  templateUrl: './attach-documents.component.html',
  styleUrls: ['./attach-documents.component.scss'],
})
export class AttachDocumentsComponent implements OnInit {
  @Output() submit = new EventEmitter<Partial<DocumentSubmit>[]>();
  @Input() docList: Partial<DocumentSubmit>[] = [];

  docForm: UntypedFormBuilder | any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dataService: DataService,
    public blobService: BlobStorageService
  ) {}

  ngOnInit(): void {
    this.docForm = this.formBuilder.group({
      documents: this.formBuilder.array([]),
    });
    this.setInitialDocs();
  }
  fileName: string = '';

  getControls() {
    if (this.docForm) {
      return (this.docForm.get('documents') as UntypedFormArray).controls;
    }
    return [];
  }

  deleteFile(index: number) {
    let control = this.getControls();

    if (index > -1) {
      control.splice(index, 1);
    }
  }

  setInitialDocs() {
    let control = <UntypedFormArray>this.docForm?.controls.documents;
    this.docList.forEach((x) => {
      control.push(
        this.formBuilder.group({
          id: [x.id],
          documentPath: [x.documentPath, Validators.required],
          type: [x.type],
          isFileOnRightBlob: [x.isFileOnRightBlob],
        })
      );
    });
  }

  updateFile(settings: Partial<FileSetttings>) {
    let control = <UntypedFormArray>this.docForm?.controls.documents;
    control.push(
      this.formBuilder.group({
        documentPath: [settings.uploadedFileName],
        type: ['document'],
        isFileOnRightBlob: true,
      })
    );
  }
}

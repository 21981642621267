<form
  [formGroup]="marketForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div
      class="form-floating mb-3"
      [appValidateState]="marketForm.get('title')"
    >
      <div class="inpt-title">{{ "title" | transloco }}</div>
      <input
        id="header"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="{{ 'title' | transloco }}"
        i18n-placeholder="@@header"
        autocomplete="off"
      />
    </div>
    <div>
      <div class="inpt-title">{{ "description" | transloco }}</div>
      <textarea
        id="description"
        formControlName="description"
        type="text"
        class="inpt inpt-lg"
        placeholder="{{ 'description' | transloco }}"
        i18n-placeholder="@@description"
        autocomplete="off"
      ></textarea>
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="marketForm.get('mwEventId')"
    >
      <app-datalist-select
        class=""
        [activeValue]="marketForm?.value.mwEventId"
        [options]="eventsOptions"
        (activeValueChange)="updateValue('mwEventId', $event)"
        [multiple]="false"
        label="{{ 'event' | transloco }}"
        i18n-label="Event"
      ></app-datalist-select>
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="marketForm.get('marketCategoryId')"
    >
      <app-datalist-select
        class=""
        [activeValue]="marketForm?.value.marketCategoryId"
        [options]="categoriesOptions"
        (activeValueChange)="updateValue('marketCategoryId', $event)"
        [multiple]="false"
        label="{{ 'category' | transloco }}"
        i18n-label="Category"
      ></app-datalist-select>
    </div>
    <div
      class="d-flex form-floating col inputsModals displayNoneOnSmallScreens"
    >
      <input id="file" class="borderRadius8 form-control mt-3" type="file" />
      <label for="file" i18n="@@WhistleFile"
        >{{ "whistleFile" | transloco }}</label
      >
    </div>
    <div class="pt-2 col-4 h-100 displayNoneOnBigScreens">
      <div
        class="ps-2 pt-2"
        style="
          /* Base/White */
          height: 40px;
          width: 152px;
          background: #ffffff; /* Gray/300 */
          border: 1px solid #d0d5dd; /* Shadow/xs */
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
        "
      >
        <svg
          for="custom-file-input"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613"
            stroke="#344054"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ "uploadFile" | transloco }}
        
        <input id="custom-file-input" type="file" class="custom-file-input" />
      </div>
    </div>
  </div>

  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
      {{ "save" | transloco }}
    </button>
  </div>
</form>
<app-error-alert
  title="{{ 'postError' | transloco }}"
  i18n-title="Message post submit error alert"
  [errors]="errors"
  [enableCancel]="true"
></app-error-alert>

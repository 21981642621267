import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';
import { DocumentTypesRepository } from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { TenantFeatures } from 'app/state/feature.repository';
import { IMenuItem } from '../menu-item/menu-item.component';
import { combineLatest, zip } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantsService } from 'app/state/tenants.service';
import { TenantHomeUpdateModel } from 'app/state/tenants.repository';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GeneralDataRepository } from 'app/helpers/repository/general-data.repository';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  subMenuItems: string[] | undefined;
  subMenuName: string | undefined;

  menuItems: IMenuItem[] = [];

  homeTenantModel!: TenantHomeUpdateModel;
  videpUrlSafe: SafeResourceUrl | undefined;
  imageSafe: SafeResourceUrl | undefined;

  constructor(
    public authRepo: AuthRepository,
    public doctyperepo: DocumentTypesRepository,
    public doctypeservice: DocumentTypesService,
    private router: Router,
    private tenantService: TenantsService,
    public sanitizer: DomSanitizer,
    private generalDataRepository: GeneralDataRepository,
    public blobService: BlobStorageService
  ) {}

  ngOnInit(): void {
    this.generalDataRepository.allMobileMenuItems$.subscribe((items) => {
      this.menuItems = items;
    });
    this.setTenantModelAndUrls();
  }

  tenantHasNoInfo() {
    if (
      this.homeTenantModel.headline == '' &&
      this.homeTenantModel.logo == '' &&
      this.homeTenantModel.pageHtml == '' &&
      this.homeTenantModel.videoUrl == '' &&
      this.homeTenantModel.videoUrl == ''
    ) {
      return true;
    }
    return false;
  }

  setTenantModelAndUrls() {
    this.homeTenantModel = {
      headline: '',
      logo: '',
      pageHtml: '',
      pageHtml2: '',
      videoUrl: '',
    };
    var tenantId = localStorage.getItem('activeTenantId');
    if (tenantId) {
      this.tenantService.loadTenantHomePageModel(tenantId).subscribe({
        next: (responce) => {
          this.homeTenantModel = responce;
          this.videpUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
            responce.videoUrl!
          );
          if (this.homeTenantModel.logo) {
            this.imageSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.blobService.getCompressedImagePath(
                this.homeTenantModel.logo,
                800,
                this.homeTenantModel.isFileOnRightBlob ?? false,
                this.homeTenantModel.hasCompressedImage800
              )
            );
          }
        },
      });
    }
  }

  reloadToMainMenu() {
    this.router.navigate([], {
      queryParams: {},
    });
  }

  private getItemLink(name: string): string {
    switch (name) {
      case TenantFeatures.News:
        return '/posts';
      case TenantFeatures.Calendar:
        return '/events';
      case TenantFeatures.Collaborations:
        return '/mwevents';
      case TenantFeatures.OutOfOfficeStatus:
        return '/out-of-office-status';
      default:
        return `/${name.toLowerCase()}`;
    }
  }

  private getImageLink(name: string): string {
    return `assets/icons/icon-${name.toLowerCase()}.svg`;
  }

  private getSubItems(name: string): IMenuItem[] | undefined {
    switch (name) {
      default:
        return undefined;
    }
  }

  openSubMenu(items: IMenuItem[], name: string) {
    this.menuItems = items;
    this.router.navigate([], {
      queryParams: { subMenuOpened: name },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
}

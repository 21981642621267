import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthRepository } from 'app/state/auth.repository';
import { Tenant, TenantsRepository } from 'app/state/tenants.repository';
import { User, UsersRepository } from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
import { SortProperty } from 'app/interfaces/sort-property';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  //@Input() page: number = 1;
  //@Input() total!: number;
  @Output() pageChange = new EventEmitter<number>();
  //pages: number[] = [];
  isRestore: boolean = false;
  classes = ['col-xl-8', 'col-md-', 'col-sm-8', 'col-9', 'd-flex', 'p-sm-0'];
  sortProps: SortProperty[] = [
    {
      target: 'name',
      classes: this.classes,
    },
  ];

  @Input() innerWidth = 0;
  @Input() users: User[] | null = null;
  @Input() isDeleteDisabled = true;
  @Output() delete = new EventEmitter<string>();
  @Output() restore = new EventEmitter<string>();
  actionConfirmation: User | null = null;

  constructor(
    public repo: UsersRepository,
    public tenantRepo: TenantsRepository,
    public service: UsersService,
    public auth: AuthRepository,
    public blobService: BlobStorageService
  ) {}

  ngOnInit(): void {}

  handleActionClick(user: User) {
    if (this.isRestore) {
      this.restore.emit(user.id);
      this.isRestore = false;
    } else this.delete.emit(user.id);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    let reload = localStorage.getItem('go_reload');
    if (reload) {
      localStorage.removeItem('go_reload');
      window.location.reload();
    }
  }

  ngAfterContentChecked() {
    let reload = localStorage.getItem('go_reload');
    if (reload) {
      localStorage.removeItem('go_reload');
      window.location.reload();
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolboxesAdminComponent } from './pages/toolboxes-admin/toolboxes-admin.component';
import { ToolboxesComponent } from './pages/toolboxes/toolboxes.component';
import { ToolboxesEditComponent } from './pages/toolboxes-edit/toolboxes-edit.component';
import { ToolboxesInfoComponent } from './pages/toolboxes-info/toolboxes-info.component';
import { ToolboxesFormComponent } from './components/toolboxes-form/toolboxes-form.component';
import { ToolboxesTabComponent } from './components/toolboxes-tab/toolboxes-tab.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { BaseModule } from '../base/base.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ToolboxAreasComponent } from './pages/toolbox-areas/toolbox-areas.component';
import { ToolboxAreaEditComponent } from './pages/toolbox-area-edit/toolbox-area-edit.component';
import { ToolboxAreaAddComponent } from './pages/toolbox-area-add/toolbox-area-add.component';
import { ToolboxAreaFormComponent } from './components/toolbox-area-form/toolbox-area-form.component';
import { ToolboxNotificationCreateComponent } from './pages/toolbox-notification/toolbox-notification.component';

@NgModule({
  declarations: [
    ToolboxesAdminComponent,
    ToolboxesComponent,
    ToolboxesEditComponent,
    ToolboxesInfoComponent,
    ToolboxesFormComponent,
    ToolboxesTabComponent,
    ToolboxAreasComponent,
    ToolboxAreaEditComponent,
    ToolboxAreaAddComponent,
    ToolboxAreaFormComponent,
    ToolboxNotificationCreateComponent,
  ],
  imports: [
    BaseModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    FullCalendarModule,
    MatDialogModule,
    TranslocoRootModule,
    BaseModule,
    CoreModule,
    SharedModule,
  ],
})
export class ToolboxModule {}

import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { User } from './users.repository';
import { IMarketEntity } from './marketplace.repository';

export const MarketLogsBuySortOptions: SortOption[] = [
  {
    label: $localize`:Sort label Created At:Created At`,
    property: 'CreatedAt',
  },
];
export interface IMarketEventLog {
  id: string;
  userId: string;
  marketEventId: string;
  actionType: string;
  createdAtString: string;
  updatedAt: Date;
  createdAt: Date;
  user: User;
  marketEvent: IMarketEntity;
}

@Injectable({ providedIn: 'root' })
export class MarketplaceLogsBuyRepository extends BaseRepository<IMarketEventLog> {
  constructor() {
    super('MarketLogsBuySortOptions', MarketLogsBuySortOptions);
  }
}

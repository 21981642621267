import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToolboxAreaForListDto } from 'app/api/models/toolbox/toolboxAreaForListDto';
import { SortProperty } from 'app/interfaces/sort-property';
import {
  IToolboxArea,
  ToolboxAreaRepository,
  ToolboxAreaSortOptions,
} from 'app/state/toolboxarea.repository';
import { ToolboxAreaService } from 'app/state/toolboxarea.service';

@Component({
  selector: 'app-toolbox-areas',
  templateUrl: './toolbox-areas.component.html',
  styleUrls: ['./toolbox-areas.component.scss'],
})
export class ToolboxAreasComponent implements OnInit {
  innerWidth = 0;
  deleteConfirmation: ToolboxAreaForListDto | null = null;
  errorTitle: string = '';
  errors: string[] | undefined;
  toolboxAreas: ToolboxAreaForListDto[] = [];
  filteredToolboxAreas: ToolboxAreaForListDto[] = [];
  dataLoading$: boolean = true;
  classes = ['col-3', 'd-flex', 'psm0'];

  sortProps: SortProperty[] = [
    {
      target: 'createdAt',
      label: 'date',
      classes: ['col-3', 'psm0'],
    },
    {
      target: 'name',
      classes: ['col-2', 'psm0'],
      isAscending: true,
    },
    {
      target: 'userGroups',
      classes: ['col-2', 'psm0'],
      disableSort: true,
    },
    {
      target: 'isActive',
      classes: ['col-2', 'psm0'],
    },
  ];

  constructor(
    public repo: ToolboxAreaRepository,
    public service: ToolboxAreaService,
    private router: Router
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.refreshData();
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.loadAll().subscribe((x) => {
      this.toolboxAreas = x;
      this.filteredToolboxAreas = x;
      this.dataLoading$ = false;
    });
  }

  handleDeleteClick(tba: ToolboxAreaForListDto) {
    this.service.deleteWithQueryId(tba.id).subscribe((x) => {
      this.refreshData();
    });
  }

  handleErrorCancel() {
    this.errors = undefined;
  }

  onItemClick(id: string) {
    if (this.innerWidth < 576) {
      this.router.navigate(['admin', 'toolbox-areas', 'edit', id]);
    }
  }

  getGroupFieldValue(groups: string[]) {
    if (groups.length > 1) {
      return groups[0] + '...';
    }
    return groups[0];
  }
}

<app-spinner *ngIf="this.loading"></app-spinner>
<form
  *ngIf="!this.loading"
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">
      <div class="inpt-title">{{ "name" | transloco }}</div>
      <input formControlName="name"
             type="text"
             class="inpt"
             placeholder="{{ 'name' | transloco }}"
             autocomplete="off" />
    </div>
    <div class="form-floating mb-4" >
      <div class="inpt-title">{{ "userGroups" | transloco }}</div>
      <app-datalist-select [options]="groupsOptions"
                           [multiple]="true"
                           [activeValue]="editForm?.value.userGroupIds"
                           (activeValueChange)="updateValue('userGroupIds', $event)"
                           ></app-datalist-select>
    </div>
    <div class="form-floating d-flex align-items-center"
         [appValidateState]="editForm.get('isActive')">
      <div>
        <label class="toggle">
          <input class="toggle-checkbox form-check-input"
                 type="checkbox"
                 formControlName="isActive" />
          <div class="toggle-switch"></div>
        </label>
      </div>
      <div class="ms-2">
        <label>{{ "active" | transloco }}</label>
      </div>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
      {{ "save" | transloco }}
    </button>
  </div>
</form>

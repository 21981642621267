import { Component, Input } from '@angular/core';
import { CollaborationInfo } from 'app/state/mwevent.repository';
import { Tenant } from 'app/state/tenants.repository';

@Component({
  selector: 'app-tenant-info-component',
  templateUrl: './tenant-info-component.component.html',
  styleUrls: ['./tenant-info-component.component.scss'],
})
export class TenantInfoComponentComponent {
  @Input() collabs: CollaborationInfo[] | null = null;
  @Input() tenantName: string | null = null;
  ngOnInit() {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Post, PostsRepository } from './posts.repository';
import { switchMap, tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { IComments } from './mwevent.repository';
import { DataService } from 'app/shared/data.service';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';

const API = '/api/posts';
const APIComment = '/api/comment';

@Injectable({ providedIn: 'root' })
export class PostsService extends BaseService<Post> {
  constructor(
    http: HttpClient,
    repo: PostsRepository,
    dataService: DataService
  ) {
    super(API, http, repo, dataService);
  }

  load() {
    return this.http.get<PaginationData & { data: Post[] }>(API);
  }

  AddComment(comment: IComments) {
    return this.http.post<IComments>(APIComment, comment);
  }

  loadAll(): Observable<Post[]> {
    return this.http.get<Post[]>(API + '/all').pipe(
      tap((posts) => {
        this.repo.clear();
        this.repo.set(posts);
      })
    );
  }

  loadOneForAdmin(id: string, includeAttributes: string[] = ['']) {
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http.get<Post>(`${API}/${id}?${query.join('&')}`);
  }
  postDocumentsForPost(id: string, documents: DocumentSubmit[]) {
    return this.http.post<any>(`${API}/postDocuments/${id}`, documents);
  }

  add(post: Partial<Post>, includeAttributes: string[] = ['']) {
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http.post<Post>(`${API}?${query.join('&')}`, post);
  }

  update(id: string, post: Partial<Post>, includeAttributes: string[] = ['']) {
    const query = [`includeAttributes=${includeAttributes}`, `id=${id}`];
    return this.http.put<Post>(`${API}/edit/?${query.join('&')}`, post);
  }
  getCount() {
    return this.http.get<number>(`${API}/unread`);
  }

  loadFilterPageCustom(
    active = 'All',
    search: string = '',
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = ['']
  ): Observable<PaginationData & { data: Post[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `active=${active}`,
      `includeAttributes=${includeAttributes}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: Post[] }>(`${API}/page?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadPageCustom(
    active = 'All',
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    includeAttributes: string[] = ['']
  ): Observable<PaginationData & { data: Post[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `active=${active}`,
      `includeAttributes=${includeAttributes}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: Post[] }>(`${API}/page?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  reloadPageCustom(
    active = 'All',
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = ['']
  ): Observable<(PaginationData & { data: Post[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        active,
        data.currentPage,
        data.perPage,
        search,
        includeAttributes
      );
    }
    return this.loadPageCustom(
      active,
      1,
      defaultTake,
      search,
      includeAttributes
    );
  }

  searchReloadPageCustom(
    active = 'All',
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = ['']
  ): Observable<(PaginationData & { data: Post[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        active,
        1,
        data.perPage,
        search,
        includeAttributes
      );
    }
    return this.loadPageCustom(
      active,
      1,
      defaultTake,
      search,
      includeAttributes
    );
  }
}

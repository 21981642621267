import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Post } from 'app/state/posts.repository';
import { PostsService } from 'app/state/posts.service';
import {
  UserGroup,
  UserGroupsRepository,
} from 'app/state/usergroup.repository';
import { UserGroupsService } from 'app/state/usergroup.service';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss'],
})
export class NewsEditComponent implements OnInit {
  post: Post | null = null;
  id: string | null = null;
  submitErrors: string[] | null = null;

  constructor(
    private service: PostsService,
    private router: Router,
    private route: ActivatedRoute,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository
  ) {}
  userGroup: UserGroup | null = null;
  groups: UserGroup[] | null = null;
  deletedGroups: UserGroup[] | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value: any) => {
        this.id = value.get('id')!;
        if (this.id) {
          this.loadEntity(this.id);
        }
        this.userGroupsService
          .loadAllDeleted()
          .subscribe((x) => (this.deletedGroups = x));
        this.userGroupsService.loadAll().subscribe((x) => (this.groups = x));
      },
    });
  }

  loadEntity(id: string) {
    this.service
      .loadOneForAdmin(id, ['userGroups', 'documents'])
      .subscribe((x) => {
        this.post = x;
      });
  }

  updatePost(post: Partial<Post>) {
    this.submitErrors = null;
    let updateResult: Observable<Post> | undefined;
    if (this.id === 'new') {
      updateResult = this.service.add(post, [
        'userGroups',
        'createdBy',
        'comments',
        'documents',
        'updatedBy',
      ]);
    } else if (this.id) {
      updateResult = this.service.update(this.id, post, [
        'userGroups',
        'createdBy',
        'comments',
        'documents',
        'updatedBy',
      ]);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/admin/posts']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}

import { Component, HostListener } from '@angular/core';
import { MarketplaceService } from 'app/state/marketpalce.service';
import {
  IMarketEntity,
  MarketSortOptions,
  MarketplaceRepository,
} from 'app/state/marketplace.repository';
import { MarketplaceAllService } from '../../../../state/marketpalceAll.service';
import { MarketplacePrivateService } from '../../../../state/marketpalcePrivate.service';
import { MarketplaceAllRepository } from '../../../../state/marketplaceAll.repository';
import { MarketplacePrivateRepository } from '../../../../state/marketplacePrivate.repository';

@Component({
  selector: 'app-marketplace-private-page',
  templateUrl: './marketplace-private-page.component.html',
  styleUrls: ['./marketplace-private-page.component.scss'],
})
export class MarketplacePrivatePageComponent {
  sortOptions = MarketSortOptions;
  innerWidth = 0;
  searchFilter: string = '';
  deleteConfirmation: IMarketEntity | null = null;
  constructor(
    public repo: MarketplacePrivateRepository,
    public service: MarketplacePrivateService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  refreshDataProject() {
    this.service.reloadPage(this.searchFilter).subscribe();
    /*  this.repo.all$;*/
  }
  handleDeleteClick(post: IMarketEntity) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshDataProject();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateNewNotificationDto } from 'app/api/models/notifications/createNewNotificationDto';
import { NotificationService } from 'app/api/services/notificationService';

@Component({
  selector: 'app-manual-notification-create-page',
  templateUrl: './manual-notification.component.html',
  styleUrls: ['./manual-notification.component.scss'],
})
export class ManualNotificationComponent implements OnInit {
  sprintId: string = '';
  form: UntypedFormBuilder | any;


  @Input() entityId = ''
  @Input() notificationType = 9;

  @Output() createNewNotificationDto = new EventEmitter<CreateNewNotificationDto>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.createEmptyFormGroup();
  }

  createEmptyFormGroup(): void {
    this.form = this.formBuilder.group({
      body: new FormControl(``),
      preview: new FormControl(``),
    });
  }

  onSubmit() {
    var result = new CreateNewNotificationDto();
    result.body = this.form.controls['body'].value;
    result.preview = this.form.controls['preview'].value;
    result.entityId = this.entityId;
    result.notificationType = this.notificationType;

    this.createNewNotificationDto.emit(result);
  }
}

import { Component } from '@angular/core';
import { MarketplaceService } from '../../../../state/marketpalce.service';
import {
  IMarketEntity,
  MarketplaceRepository,
} from '../../../../state/marketplace.repository';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MWEventService } from '../../../../state/mwevent.service';
import { MarketCategoriesService } from '../../../../state/marketcategories.service';
import { MarketCategoriesRepository } from '../../../../state/marketcategories.repository';
import {
  IMWEvent,
  MWEventsRepository,
} from '../../../../state/mwevent.repository';

@Component({
  selector: 'app-marketplace-create',
  templateUrl: './marketplace-create.component.html',
  styleUrls: ['./marketplace-create.component.scss'],
})
export class MarketplaceCreateComponent {
  constructor(
    public service: MarketplaceService,
    public repo: MarketplaceRepository,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private mwEventService: MWEventService,
    public mwEventRepo: MWEventsRepository,
    private categoryService: MarketCategoriesService,
    public categoriesRepo: MarketCategoriesRepository
  ) {}

  events: IMWEvent[] | null = null;
  marketEntity: IMarketEntity | null = null;
  id: string = '';
  tenantId: string | null = null;
  diffTenantId: string | null = null;
  type!: string;
  submitErrors: string[] | null = null;
  ngOnInit(): void {
    this.mwEventService.reloadPage().subscribe();
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type')!;
        this.id = value.get('id') || '';
        if (this.id && this.type !== 'new') {
          this.service.loadOneMarketEvent(this.id).subscribe((y) => {
            this.marketEntity = y;
            this.diffTenantId = y.tenantId;
            this.tenantId = localStorage.getItem('activeTenantId');
            if (
              this.diffTenantId &&
              this.tenantId &&
              this.diffTenantId !== this.tenantId
            ) {
              this.mwEventService
                .getForDiffTenant(this.diffTenantId)
                .subscribe((x) => {
                  this.events = x;
                });
            }
          });
        }
      },
    });
    this.categoryService.load().subscribe();
  }

  createMarketEntity(marketEntity: Partial<IMarketEntity>) {
    this.submitErrors = null;
    let updateResult: Observable<IMarketEntity> | undefined;
    if (this.type === 'new') {
      updateResult = this.service.add(marketEntity);
    } else if (this.id) {
      marketEntity.id = this.id;
      updateResult = this.service.updateWithTenantOption(this.id, false, marketEntity);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { TenantsRepository } from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';
import {
  AuthRepository,
  PasswordChangeRequest,
  UserRoles,
} from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { User, UsersRepository } from 'app/state/users.repository';
import { UserCreateUpdateDto, UsersService } from 'app/api/services/users.service';
import { UrlIdResolverService } from 'app/modules/shared/services/url-id-resolver.service';
import { HttpClient } from '@angular/common/http';
import { UserGroupsService } from 'app/state/usergroup.service';
import {
  UserGroup,
  UserGroupsRepository,
} from 'app/state/usergroup.repository';
import { GeneralDataRepository } from '../../../../helpers/repository/general-data.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-users-create-page',
  templateUrl: './users-create-page.component.html',
  styleUrls: ['./users-create-page.component.scss'],
})
export class UsersCreatePageComponent implements OnInit {
  user$: Observable<User | undefined> | undefined;
  user: User | undefined;
  id?: string;
  tenantOptions: DatalistOption[] | null = null;

  submitErrors: string[] | null = null;
  constructor(
    private usersService: UsersService,
    private tenantService: TenantsService,
    public tenantRepo: TenantsRepository,
    private auth: AuthRepository,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public repo: UsersRepository,
    private urlId: UrlIdResolverService,
    private sidebarRepo: GeneralDataRepository,
    private http: HttpClient,
    public authRepo: AuthRepository,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository
  ) {}
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.id = params.get('id')!;
    });
    if (
      this.auth.isInRole(UserRoles.Superadmin) ||
      (this.auth.isInRole(UserRoles.PartnerManager) &&
        this.auth.isPartnerTenant())
    ) {
      this.tenantService.load().subscribe();
    }
    this.userGroupsService.loadAll().subscribe();
    this.usersService.loadAllCoaches().subscribe();
    this.repo.all$.subscribe();
    this.tenantService.loadForDatalist().subscribe((x) => {
      this.tenantOptions = x;
    });
  }

  onInfoSubmit(user: UserCreateUpdateDto) {
    this.submitErrors = null;
    if (!this.id) {
      throw 'Can not identify current user';
    }
    let updateResult: Observable<User> | undefined;
    if (this.id === 'new') {
      updateResult = this.usersService.add(user);
    } else if (this.id) {
      updateResult = this.usersService.update(this.id, user);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.sidebarRepo.updateAllGeneralData(true);
          this.router.navigate(['/users']);
        },
        error: (data) => {
          this.submitErrors = data;
        },
      });
    }
  }

  onPasswordSubmit(dto: PasswordChangeRequest) {
    this.submitErrors = null;
    this.http.put<void>(`/api/auth/resetpassword/${this.id}`, dto).subscribe({
      next: (x: any) => {
        if (x) {
          this.router.navigate(['/users']);
        }
      },
      error: (data) => (this.submitErrors = data),
    });
  }
}

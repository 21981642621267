<div>
  <div style="display: flex">
    <div class="ms-3">
      <label>
        <input
          class="toggle-checkbox"
          type="checkbox"
          id=" isAnonymous"
          [ngModel]="gdprConfirmed"
          (click)="submitGdprInput()"
        />
        <div class="toggle-switch"></div>
      </label>
    </div>
    
    <div class="ms-3">
      <h6>{{ IgnitePrivacyAndCookieConfirmation }}</h6>
    </div>
    <div class="ms-3">
      <h6>
        {{ ToReadMoreClick }}
        <a href="{{ redirectGDPRUrl }}" target="_blank">{{ here }}</a
        >.
      </h6>
      <h6></h6>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between">
    <button
      [disabled]="!gdprConfirmed"
      (click)="submitGdpr()"
      class="btn btn-orange border8 btntxt btn-default btn-block mt-3"
      style="background-color: #ff4405; width: 5rem"
    >
      <ng-container class="textBold" i18n="Button sign in">{{
        "submit" | transloco
      }}</ng-container>
    </button>
    <button
      (click)="rejectGdpr()"
      class="btn btn-secondary border8 btntxt btn-default btn-block mt-3"
      style="background-color: #6e6e6e; width: 5rem"
    >
      <ng-container class="textBold" i18n="Button sign in">Skip</ng-container>
    </button>
  </div>
</div>

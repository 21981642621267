<div
  class="d-flex responsiveMedia"
  *ngIf="
    (authRepo.isUser$ | async) || (authRepo.isTenantAdmin$ | async);
    else elseBlock
  "
>
  <div class="p-2 w-100 page">
    <div *ngIf="menuItems" class="mt-2 row m-0 p-0">
      <app-menu-item
        *ngFor="let item of menuItems"
        [ngClass]="menuItems.length > 4 ? 'col-4' : 'col-6'"
        class="d-flex justify-content-center"
        [item]="item"
        (subMenuOpen)="openSubMenu($event, item.name)"
      >
      </app-menu-item>
    </div>
  </div>
</div>
<div class="showOnBigScreen">
  <div class="row justify-content-center">
    <div class="row col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3 ps-0 pe-0">
      <div *ngIf="tenantHasNoInfo(); else elseBlock">
        <h2 class="sm-del page-title col-sm-4 col-12">
          {{ "home" | transloco }}
        </h2>

        <div class="useTheMenu">{{ "useMenu" | transloco }}</div>
      </div>
      <ng-template #elseBlock>
        <div>
          <img *ngIf="homeTenantModel.logo" class="img" [src]="imageSafe" />
          <h1 class="mb-4 mt-4">{{ homeTenantModel.headline }}</h1>

          <div [innerHtml]="homeTenantModel.pageHtml"></div>

          <div class="col-lg-9 col-12" *ngIf="homeTenantModel.videoUrl">
            <div class="">
              <div>
                <iframe
                  frameBorder="0"
                  [src]="videpUrlSafe"
                  class="mainVideo"
                  allowscriptaccess="always"
                ></iframe>
              </div>
            </div>
          </div>

          <div [innerHtml]="homeTenantModel.pageHtml2"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <!--head administrator-->
</ng-template>

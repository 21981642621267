<div
  role="button"
  [routerLink]="['/marketplace/info', market?.id]"
  class="card mb-2"
  style="width: 18rem; border-radius: 20px"
>
  <div
    class="d-flex align-items-center justify-content-center"
    style="
      min-height: 200px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    "
  >
    <img
      *ngIf="market?.imagePath"
      class="news_img"
      src="{{ market?.imagePath }}"
      onerror="this.onerror=null; this.src='assets/img/market.jpg'"
    />
    <img
      *ngIf="!market?.imagePath"
      class="news_img"
      src="{{ DEFAULT_MARKET }}"
    />
  </div>
  <div class="card-body" style="min-height: 150px">
    <h5 class="card-title">
      {{ market?.title | slice : 0 : 50 }}
    </h5>
    <div class="d-flex">
      <p [innerHTML]="market?.description?.slice(0, 25)"></p>
      <p
        style="white-space: pre"
        *ngIf="market?.description && market?.description?.length! >= 25"
      >
        ...
      </p>
    </div>
    <a class="grey" [routerLink]="['/marketplace/info', market?.id]"
      >{{ "readMore" | transloco }}
    </a>
  </div>
</div>

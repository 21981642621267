import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ToolBox } from 'app/state/toolbox.repository';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AttachedLink } from 'app/state/abstract/base.repository';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { ToolBoxesService } from 'app/api/services/toolbox.service';

@Component({
  selector: 'app-toolboxes-info',
  templateUrl: './toolboxes-info.component.html',
  styleUrls: ['./toolboxes-info.component.scss'],
})
export class ToolboxesInfoComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';
  readonly DEFAULT_POST_IMAGE = 'assets/img/preview.jpg';
  toolBox: ToolBox | null = null;
  Form: UntypedFormBuilder | any;
  id: string | null = null;
  submitErrors: string[] | null = null;
  previewDocuments: Partial<DocumentSubmit>[] = [];
  attachedDocuments: Partial<DocumentSubmit>[] = [];
  attachedLinks: Partial<AttachedLink>[] = [];

  constructor(
    private toolBoxService: ToolBoxesService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public sanitizer: DomSanitizer,
    public blobService: BlobStorageService
  ) {}

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      text: [null, Validators.required],
    });
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        this.toolBoxService
          .loadOneWithQueryId(`${this.id}`, [
            'read',
            'documents',
            'attachedLinks',
          ])
          .subscribe((x) => {
            this.toolBox = x;
            if (x) {
              if (!x.isRead) {
                this.toolBoxService.markAsRead(x.id).subscribe();
              }
              this.previewDocuments = x.documents.filter((x: DocumentSubmit) =>
                ['image', 'videoUrl', 'video'].includes(x.type)
              );
              this.previewDocuments.forEach((d: any) => {
                switch (d.type) {
                  case 'video': {
                    if (d.video) {
                      d.video = this.sanitizeUrl(
                        this.blobService.getFilePathFromBlob(
                          d.video,
                          d.isFileOnRightBlob
                        )
                      );
                    }
                    break;
                  }
                  case 'image': {
                    if (d.image) {
                      d.image = this.sanitizeUrl(
                        this.blobService.getCompressedImagePath(
                          d.image,
                          800,
                          d.isFileOnRightBlob ?? false,
                          d.hasCompressedImage800
                        )
                      );
                      // d.image = this.sanitizeUrl(
                      //   this.blobService.getFilePathFromBlob(
                      //     d.image,
                      //     d.isFileOnRightBlob
                      //   )
                      // );
                    }
                    break;
                  }
                  case 'videoUrl': {
                    if (d.videoUrl) {
                      d.videoUrl = this.sanitizeUrl(d.videoUrl);
                    }
                    break;
                  }
                }
              });
              this.attachedLinks = x.attachedLinks;
              // this.attachedLinks.forEach(
              //   (l: any) => (l.url = this.sanitizeUrl(l.url))
              // );

              this.attachedDocuments = x.documents.filter(
                (x: DocumentSubmit) =>
                  !['image', 'videoUrl', 'video'].includes(x.type)
              );
            }
          });
      },
    });
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onSubmit() {
    if (this.Form && !this.Form.valid) {
      this.Form.markAllAsTouched();
      return;
    }
  }

  openLinkInNewTab(src?: string) {
    if (src) {
      window.open((src = src), '_blank');
    }
  }
}

import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Feature, TenantFeatures } from 'app/state/feature.repository';
import { Tenant } from 'app/state/tenants.repository';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { AuthRepository } from 'app/state/auth.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';

const FEATURE_NAMES: { [f: string]: string } = {
  [TenantFeatures.News]: $localize`:News label:News`,
  [TenantFeatures.Calendar]: $localize`:Calendar label:Calendar`,
  [TenantFeatures.TimeLogs]: $localize`:TimeLogs label:TimeLogs`,
  [TenantFeatures.Collaborations]: $localize`:Collaboration label:Collaborations`,
  [TenantFeatures.Whistleblow]: $localize`:Whistleblow label:Whistleblow`,
  [TenantFeatures.Messages]: $localize`:Messages label:Messages`,
  [TenantFeatures.Apv]: $localize`:Apv label:Apv`,
  [TenantFeatures.Documents]: $localize`:Documents label:Documents`,
  [TenantFeatures.Maps]: $localize`:Maps label:Maps`,
  [TenantFeatures.Notifications]: $localize`:Notifications label:Notifications`,
  [TenantFeatures.Marketplace]: $localize`:Marketplace label:Marketplace`,
  [TenantFeatures.Email]: $localize`:Email label:Email`,
  [TenantFeatures.ToolBox]: $localize`:ToolBox label:ToolBox`,
  [TenantFeatures.PersonalPlan]: $localize`:PersonalPlan label:PersonalPlan`,
  [TenantFeatures.OutOfOfficeStatus]: $localize`:OutOfOfficeStatus label:OutOfOfficeStatus`,
  [TenantFeatures.Onboarding]: $localize`:Onboarding label:Onboarding`,
  [TenantFeatures.Feed]: $localize`:Feed label:Feed`,
};

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.scss'],
})
export class TenantFormComponent implements OnInit {
  form?: UntypedFormGroup;

  file: File | null = null;

  myOptions: any;
  documentTypeId: string | undefined = undefined;
  isPartner: boolean = false;
  featureOptions: DatalistOption[] | null = null;
  calendarHere$ = false;
  deleteDocumentRequired: boolean = false;
  currentFileName: string = '';

  @Input() editedTenant?: Tenant | null;

  @Input() set features(value: Feature[] | null) {
    if (!value) {
      this.featureOptions = null;
    } else {
      this.featureOptions = value.map((x) => ({
        value: x.id,
        label: FEATURE_NAMES[x.name],
      }));
    }
  }

  @Output() tenantSubmit = new EventEmitter<Partial<Tenant>>();

  img: HTMLImageElement = document.createElement('img');
  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.form) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        if (this.file?.type) {
          if (this.file?.type.slice(0, 5) !== 'image') {
            this.file = null;
          }
          if (this.file?.size) {
            var blob = URL.createObjectURL(this.file);
            this.img.src = blob;
          }
        }
        this.currentFileName = 'Tenant Logo';
        this.deleteDocumentRequired = false;
      }
    }
    this.clearInput();
  }

  constructor(
    public authRepo: AuthRepository,
    private formBuilder: UntypedFormBuilder,
    public documenttypesService: DocumentTypesService
  ) {
    this.myOptions = new Options({});
  }

  ngOnInit() {
    if (this.editedTenant?.tenantDocumentPath) {
      this.currentFileName = 'Tenant Logo';
    }
    this.documenttypesService.loadDefaultDoctype('Tenants').subscribe((x) => {
      this.documentTypeId = x.id;
    });
    this.isPartner = this.editedTenant?.isPartnerTenant ?? false;
    if (this.editedTenant) {
      this.calendarHere$ = this.editedTenant.features.some(
        (x) => x.name == 'Calendar'
      );
    }
    this.form = this.formBuilder.group({
      name: [this.editedTenant?.name, Validators.required],
      cvr: [this.editedTenant?.cvr],
      address: [this.editedTenant?.address],
      mainMail: [this.editedTenant?.mainMail],
      remarks: [this.editedTenant?.remarks],
      phoneNumber: [this.editedTenant?.phoneNumber],
      mapZoom: [this.editedTenant?.mapZoom],
      startingAddress: [this.editedTenant?.startingAddress],
      startingLat: [this.editedTenant?.startingLat],
      startingLng: [this.editedTenant?.startingLng],
      featureIds: [this.editedTenant?.features?.map((x) => x.id)],
      showCalendarAsList: [this.editedTenant?.showCalendarAsList],
      useKmAsValues: [
        this.editedTenant?.useKmAsValues != null
          ? this.editedTenant?.useKmAsValues
          : true,
      ],
      allowUnauthorizedAccess: [
        this.editedTenant?.allowUnauthorizedAccess != null
          ? this.editedTenant?.allowUnauthorizedAccess
          : false,
      ],
      //documentTitle: [this.form?.value?.documentTitle],
      documentTypeId: [this.documentTypeId],
      tenantDocumentId: [this.editedTenant?.tenantDocumentId],

      isPartnerTenant: [this.editedTenant?.isPartnerTenant ?? false],
      maxUsersCanCreate: [
        this.editedTenant?.maxUsersCanCreate,
        [Validators.min(0), Validators.pattern('^[0-9]*$')],
      ],
      maxTenantsCanCreate: [
        this.editedTenant?.maxTenantsCanCreate,
        [Validators.min(0), Validators.pattern('^[0-9]*$')],
      ],
    });
  }

  deleteDocument() {
    this.file = null;
    this.currentFileName = '';
    if (this.editedTenant?.tenantDocumentPath) {
      this.deleteDocumentRequired = true;
    }

    this.clearInput();
  }

  clearInput() {
    const fileInput = document.getElementById(
      'fileInput'
    ) as HTMLInputElement | null;
    if (fileInput && fileInput.type === 'file') {
      fileInput.value = '';
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if (control === 'featureIds')
      this.calendarHere$ = controlObject?.value.includes(
        this.featureOptions?.find((x) => x.label == 'Calendar')?.value
      );
  }

  changeIsPartner(event: any) {
    this.isPartner = event.target.checked;
    if (!this.isPartner) {
      this.form?.controls['maxUsersCanCreate'].setValue(null);
      this.form?.controls['maxTenantsCanCreate'].setValue(null);
    }
  }

  public handleAddressChange(address: any) {
    this.form?.controls['startingAddress'].setValue(address.formatted_address);
    this.form?.controls['startingLat'].setValue(
      address.geometry.location.lat()
    );
    this.form?.controls['startingLng'].setValue(
      address.geometry.location.lng()
    );
    return address.formatted_address;
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    var curTenantId = localStorage.getItem('activeTenantId');
    var tenantId = curTenantId ? curTenantId : null;
    this.tenantSubmit.emit({
      name: this.form?.value?.name.toString().trim(),
      image: this.file,
      cvr: this.form?.value?.cvr,
      address: this.form?.value?.address,
      mainMail: this.form?.value?.mainMail,
      remarks: this.form?.value?.remarks,
      phoneNumber: this.form?.value?.phoneNumber,
      mapZoom: this.form?.value?.mapZoom,
      startingAddress: this.form?.value?.startingAddress,
      startingLat: this.form?.value?.startingLat,
      startingLng: this.form?.value?.startingLng,
      featureIds: this.form?.value?.featureIds,
      showCalendarAsList: !!this.form?.value.showCalendarAsList,
      useKmAsValues: this.form?.value?.useKmAsValues,
      allowUnauthorizedAccess: this.form?.value?.allowUnauthorizedAccess,
      //documentTitle: this.form?.value?.documentTitle,
      documentTypeId: this.documentTypeId,
      imageWidth: this.img.width,
      imageHeight: this.img.height,
      tenantDocumentId: this.editedTenant?.tenantDocumentId,
      pageHtml: this.editedTenant?.pageHtml,
      isPartnerTenant: this.form?.value.isPartnerTenant,
      maxUsersCanCreate: this.form?.value.maxUsersCanCreate,
      maxTenantsCanCreate: this.form?.value.maxTenantsCanCreate,
      tenantId: tenantId!,
      tenantDocumentDeleteRequired: this.deleteDocumentRequired,
    });
  }
}

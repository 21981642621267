import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateNewNotificationDto } from 'app/api/models/notifications/createNewNotificationDto';
import { NotificationService } from 'app/api/services/notificationService';

@Component({
  selector: 'app-toolbox-notification-create-page',
  templateUrl: './toolbox-notification.component.html',
  styleUrls: ['./toolbox-notification.component.scss'],
})
export class ToolboxNotificationCreateComponent implements OnInit {
  toolboxId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        const toolboxId = value.get('id')!;
        if (toolboxId) {
          this.toolboxId = toolboxId;
        }
      },
    });
  }

  createNotification(newNotification: CreateNewNotificationDto) {
    this.notificationService.createToolboxNotification(newNotification).subscribe({
      next: () => {
        this.router.navigate(['/admin/toolbox']);
      },
    });
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from '../../state/abstract/base.service';
import { IComments } from '../../state/mwevent.repository';
import { DataService } from 'app/shared/data.service';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';
import { ToolBox, ToolBoxesRepository } from '../../state/toolbox.repository';
import { IConfirm } from '../../state/tenants.repository';
import { SortState } from 'app/modules/shared/pipes/sort.pipe';
import { ToolboxForListDto } from '../models/toolbox/toolboxForListDto';

const API = '/api/toolboxes';
const APIComment = '/api/comment';

@Injectable({ providedIn: 'root' })
export class ToolBoxesService extends BaseService<ToolBox> {
  constructor(
    http: HttpClient,
    repo: ToolBoxesRepository,
    dataService: DataService
  ) {
    super(API, http, repo, dataService);
  }

  load() {
    return this.http.get<PaginationData & { data: ToolBox[] }>(API);
  }
  sortCustom(
    sortBy: SortState,
    search?: string,
    includeAttributes: string[] = [],
    areaId: string | undefined = undefined,
    searchForAdmin: boolean = false
  ) {
    this.repo.setSort(sortBy);
    return this.reloadPageCustom(
      'All',
      search,
      DEFAULT_ENTITIES_PER_PAGE,
      includeAttributes,
      areaId,
      searchForAdmin
    );
  }
  addComment(comment: IComments) {
    return this.http.post<IComments>(APIComment, comment);
  }

  loadAll(): Observable<ToolboxForListDto[]> {
    const activeTenantId = localStorage.getItem('activeTenantId');
    return this.http.get<ToolboxForListDto[]>(
      `${API}/all?activeTenantId=${activeTenantId}`
    );
  }

  loadOneWithQueryId(id: string, includeAttributes: string[] = ['']) {
    const query = [`id=${id}`, `includeAttributes=${includeAttributes}`];
    return this.http.get<any>(`${API}/loadone?${query.join('&')}`);
  }

  loadOneForAdmin(id: string, includeAttributes: string[] = ['']) {
    const query = [`includeAttributes=${includeAttributes}`];
    return this.http.get<any>(`${API}/${id}?${query.join('&')}`, {});
  }
  ToolBoxDocumentsForToolBox(id: string, documents: DocumentSubmit[]) {
    return this.http.post<any>(`${API}/ToolBoxDocuments/${id}`, documents);
  }

  add(toolBox: Partial<ToolBox>) {
    return this.http.post<ToolBox>(`${API}/create`, toolBox);
  }

  update(id: string, toolBox: Partial<ToolBox>) {
    const query = [`id=${id}`];
    return this.http.put<ToolBox>(`${API}?${query.join('&')}`, toolBox);
  }
  getCount() {
    return this.http.get<number>(`${API}/unread`);
  }

  markAsRead(id: string) {
    const query = [`id=${id}`];
    return this.http.patch<void>(`${API}/read/?${query.join('&')}`, {});
  }

  
  loadPageCustom(
    active = 'All',
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = '',
    includeAttributes: string[] = [''],
    areaId: string | undefined,
    searchForAdmin: boolean = false
  ): Observable<PaginationData & { data: ToolBox[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
      `active=${active}`,
      `includeAttributes=${includeAttributes}`,
      `searchForAdmin=${searchForAdmin}`,
    ];
    if (areaId) {
      query.push(`areaId=${areaId}`);
    }
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: ToolBox[] }>(
        `${API}/page?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  reloadPageCustom(
    active = 'All',
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = [''],
    areaId: string | undefined,
    searchForAdmin: boolean = false
  ): Observable<(PaginationData & { data: ToolBox[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        active,
        data.currentPage,
        data.perPage,
        search,
        includeAttributes,
        areaId,
        searchForAdmin
      );
    }
    return this.loadPageCustom(
      active,
      1,
      defaultTake,
      search,
      includeAttributes,
      areaId,
      searchForAdmin
    );
  }

  searchReloadPageCustom(
    active = 'All',
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    includeAttributes: string[] = [''],
    searchForAdmin: boolean = false
  ): Observable<(PaginationData & { data: ToolBox[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(
        active,
        1,
        data.perPage,
        search,
        includeAttributes,
        undefined,
        searchForAdmin
      );
    }
    return this.loadPageCustom(
      active,
      1,
      defaultTake,
      search,
      includeAttributes,
      undefined,
      searchForAdmin
    );
  }

  clone(entity: IConfirm, activeTenantId: string) {
    return this.http.put<ToolBox>(
      `${API}/clone?activeTenantId=${activeTenantId}`,
      entity
    );
  }
}

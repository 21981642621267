<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
    <div class="col-12 col-sm-8 col-lg-6 col-xl-4 p-md-2 p-lg-0">
      <h2 class="mt-4 mb-5 page-title">{{'edit' | transloco}} {{'toolbox' | transloco}} {{'folder' | transloco}}</h2>
        <app-toolbox-area-form 
        [editedArea]="editedArea"
        [loading]="true"
        [groups]="groups">
        </app-toolbox-area-form>
    </div>
</div>

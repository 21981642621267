<div class="w-100 d-flex">
  <div *ngFor="let property of sortProperties" [ngClass]="property.classes">
    <span
      class="txt mt-1 d-flex"
      [class.disabled]="property.disableSort"
      (click)="sortByField(property)"
      >{{
        property.label
          ? (property.label | transloco)
          : (property.target | transloco)
      }}
      <div *ngIf="!property.disableSort" class="ms-2">
        <img
          *ngIf="property.isAscending != null; else unsetSort"
          src="assets/icons/down-arrow.svg"
          alt="Sort direction"
          [class.asc]="!property.isAscending"
        />
        <ng-template #unsetSort>
          <img
            src="assets/icons/up-down-arrow.svg"
            alt="Sort unset"
            [class.asc]="property.isAscending"
          />
        </ng-template>
      </div>
    </span>
  </div>
</div>

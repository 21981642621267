import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AttachDocumentsComponent } from 'app/modules/base/components/attach-documents/attach-documents.component';
import { AttachLinksComponent } from 'app/modules/base/components/attach-links/attach-links.component';
import { ImageVideoComponentComponent } from 'app/modules/base/components/image-video-component/image-video-component.component';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';
import {
  PeriodSelectComponent,
  ITimeStamp,
} from 'app/modules/base/components/period-select/period-select.component';
import {
  AttachedLink,
  DatalistOption,
} from 'app/state/abstract/base.repository';
import { ToolBox } from 'app/state/toolbox.repository';
import { IToolboxArea } from 'app/state/toolboxarea.repository';
import { ToolboxAreaService } from 'app/state/toolboxarea.service';

import {
  UserGroupsRepository,
  UserGroup,
} from 'app/state/usergroup.repository';
import { UserGroupsService } from 'app/state/usergroup.service';
import { ToolboxAreaForListDto } from 'app/api/models/toolbox/toolboxAreaForListDto';

declare var ClassicEditor: any;
@Component({
  selector: 'app-toolboxes-form',
  templateUrl: './toolboxes-form.component.html',
  styleUrls: ['./toolboxes-form.component.scss'],
})
export class ToolboxesFormComponent {
  editForm: UntypedFormBuilder | any;
  editor: any | undefined;
  isEditorLoaded: boolean = false;
  fileDocument?: Partial<Document>;
  AreaOptions: DatalistOption[] | null = null;
  allAreas: ToolboxAreaForListDto[] = [];

  @ViewChild(ImageVideoComponentComponent) documentForm:
    | ImageVideoComponentComponent
    | undefined;
  @ViewChild(AttachLinksComponent) attachLinksForm:
    | AttachLinksComponent
    | undefined;
  @ViewChild(PeriodSelectComponent) periodSelectForm:
    | PeriodSelectComponent
    | undefined;
  @ViewChild(AttachDocumentsComponent) attachDocumentsForm:
    | AttachDocumentsComponent
    | undefined;
  userGroupsOptions: DatalistOption[] | null = null;
  @Input() editedToolBox: Partial<ToolBox> | null = null;
  @Output() toolBoxSubmit = new EventEmitter<Partial<ToolBox>>();
  initialDocs: Partial<DocumentSubmit>[] = [];
  attachedLinks: Partial<AttachedLink>[] = [];

  DEFAULT_NEWS = 'assets/img/news.jpg';
  doctype: 'image' | 'video' = 'image';
  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository,
    private areaService: ToolboxAreaService
  ) {
    this.areaService.loadAll().subscribe((tba) => {
      this.allAreas = tba;
      this.updateAreas();
    });
  }

  updateAreas() {
    if (this.allAreas) {
      this.AreaOptions = this.allAreas
        .filter(
          (tba) => tba.isActive || tba.id === this.editedToolBox?.toolboxAreaId
        )
        .map((fa) => ({
          value: fa.id,
          label: fa.name,
        }));
    }
  }

  timestamp: ITimeStamp | null = null;
  ngOnInit() {
    this.initialDocs =
      this.editedToolBox?.documents?.filter((x) => x.documentPath) ?? [];
    this.fileDocument = this.editedToolBox?.documents?.find(
      (x) => !x.documentPath
    ) as Partial<Document>;
    this.timestamp = {
      from: this.editedToolBox?.showFrom,
      till: this.editedToolBox?.showTo,
    };
    this.editForm = this.formBuilder.group({
      title: [this.editedToolBox?.title, Validators.required],
      toolboxAreaId: [this.editedToolBox?.toolboxAreaId],
      editor: this.editedToolBox?.content,
      video: this.editedToolBox?.video,
      previewImage: this.editedToolBox?.previewImage,
      userGroupIds: [this.editedToolBox?.userGroupIds],
      createdById: [this.editedToolBox?.createdById],
      updatedById: [this.editedToolBox?.updatedById],
      attachedLinks: [this.editedToolBox?.attachedLinks],
    });
  }

  groupsOptions: DatalistOption[] | null = null;
  groupsOptionsDeleted: DatalistOption[] | null = null;
  @Input() set deletedGroups(value: UserGroup[] | null) {
    if (!value) {
      this.groupsOptionsDeleted = null;
    } else {
      this.groupsOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set groups(value: UserGroup[] | null) {
    if (!value) {
      this.groupsOptions = null;
    } else {
      this.groupsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  ngAfterViewInit() {
    if (typeof ClassicEditor !== 'function') {
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/assets/js/ckeditor.js';
      this.document.body.appendChild(script);
      script.onload = () => this.initEditor();
    } else {
      this.initEditor();
    }
  }

  private initEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      if (this.editedToolBox?.content) {
        newEditor.setData(this.editedToolBox?.content);
      }
      this.editor = newEditor;
      this.isEditorLoaded = true;
    });
  }

  getAttachedDocuments(): Partial<DocumentSubmit>[] {
    var res = [] as Partial<DocumentSubmit>[];
    if (this.attachDocumentsForm?.getControls()) {
      res = this.attachDocumentsForm?.getControls().map(
        (x) =>
          ({
            id: x.value.id,
            documentPath: x.value.documentPath,
            type: 'document',
            isFileOnRightBlob: x.value.isFileOnRightBlob,
          } as Partial<DocumentSubmit>)
      );
    }
    return res;
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  getAttachedLinks(): Partial<AttachedLink>[] {
    var res = [] as Partial<AttachedLink>[];
    if (this.attachLinksForm?.getControls()) {
      res = this.attachLinksForm?.getControls().map(
        (x) =>
          ({
            id: x.value.id,
            url: x.value.url,
            text: x.value.text,
          } as Partial<AttachedLink>)
      );
    }

    return res;
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    if (!this.documentForm?.checkValidity()) {
      return;
    }
    if (!this.periodSelectForm?.checkValidity()) {
      return;
    }
    const showFrom = this.periodSelectForm?.editForm.value?.from;
    const showTo = this.periodSelectForm?.editForm.value?.till;
    var attach = this.getAttachedDocuments();
    attach.push(this.documentForm.onSubmit());
    const documents = attach;
    const title = this.editForm.value?.title;
    const attachedLinks = this.getAttachedLinks();
    const content = this.editor.getData();
    const video = this.editForm.value?.video;
    const previewImage = this.editForm.value?.previewImage;
    const userGroupIds = this.editForm.value?.userGroupIds;
    const createdById = this.editForm.value?.createdById;
    const updatedById = this.editForm.value?.updatedById;
    const toolboxAreaId = this.editForm.value?.toolboxAreaId;
    this.toolBoxSubmit.emit({
      title,
      showFrom,
      showTo,
      content,
      video,
      previewImage,
      userGroupIds,
      documents,
      documentIds: this.editedToolBox?.documentIds,
      createdById,
      updatedById,
      attachedLinks,
      toolboxAreaId,
    });
  }
}

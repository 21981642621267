import { Component } from '@angular/core';
import { ToolboxAreaService } from '../../../../state/toolboxarea.service';
import { UserGroup } from '../../../../state/usergroup.repository';
import { UserGroupsService } from 'app/state/usergroup.service';

@Component({
  selector: 'app-toolbox-area-add',
  templateUrl: './toolbox-area-add.component.html',
  styleUrls: ['./toolbox-area-add.component.scss']
})
export class ToolboxAreaAddComponent {
  groups: UserGroup[] = []
  constructor(
    private groupsService: UserGroupsService,
  ) {
    groupsService.loadAll().subscribe(x => this.groups = x)
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CollaborationInfo } from 'app/state/mwevent.repository';
import { MWEventService } from 'app/state/mwevent.service';
import { Tenant } from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';

@Component({
  selector: 'app-tenant-info-page',
  templateUrl: './tenant-info-page.component.html',
  styleUrls: ['./tenant-info-page.component.scss'],
})
export class TenantInfoPageComponent {
  id: string | null = null;
  collabs: CollaborationInfo[] | null = null;
  tenant: Tenant | null = null;

  constructor(
    private route: ActivatedRoute,
    public service: MWEventService,
    public tenatService: TenantsService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id) {
          this.service.loadInfo(this.id).subscribe((x) => {
            this.collabs = x;
          });
          this.tenatService.loadOneWithoutTrack(this.id).subscribe((x) => {
            this.tenant = x;
          });
        }
      },
    });
  }
}
